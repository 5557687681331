import { useContext } from 'react';
import {
  isValidDevelopmentFlagValue,
  type DevelopmentFlagValue,
  type DevelopmentFlagKey,
} from '@mentimeter/development-flags';
import { DEFAULT_VALUE } from '../constants';
import { DevelopmentToolsContext } from '../context';

export function useDevelopmentFlag<T extends DevelopmentFlagKey>(
  flagKey: T,
): DevelopmentFlagValue<T> | typeof DEFAULT_VALUE {
  const developmentToolsContext = useContext(DevelopmentToolsContext);
  const value = developmentToolsContext.overrides.values[flagKey];

  if (isValidDevelopmentFlagValue(flagKey, value)) {
    return value;
  }

  return DEFAULT_VALUE;
}
