// @ts-expect-error-auto TS(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
export const getTrackingFields = (data): Array<string> => {
  /* This code exists because the fields in the API mean different things based on what you are changing.
        - if you're updating the email, the `password` field is the current password.
        - if you're updating the password,current_password field is the current password.
      This function checks what you're updating, and based on that sends the correct strings to the tracking-api.
      When the old Account Settings page is gone, we should remove this special case.
      https://trello.com/c/molG04x9/1359-remove-special-currentpassword-case-in-user-api
   */
  const fields = Object.keys(data);
  let filterFn = () => true;
  if (fields.includes('currentPassword')) {
    // updating password
    // @ts-expect-error-auto TS(2322) FIXME: Type '(f: any) => boolean' is not assignable to ty... Remove this comment to see the full error message
    filterFn = (f) => f !== 'currentPassword';
  }
  if (fields.includes('email')) {
    // updating email
    // @ts-expect-error-auto TS(2322) FIXME: Type '(f: any) => boolean' is not assignable to ty... Remove this comment to see the full error message
    filterFn = (f) => f !== 'password';
  }
  return fields.filter(filterFn);
};
