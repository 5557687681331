import { Box, Text } from '@mentimeter/ragnar-ui';
import { PayPalIcon } from '../icons/PayPalIcon';

interface PayPalProps {
  payPalEmail: string;
}

export function PayPalPaymentMethod({ payPalEmail }: PayPalProps) {
  return (
    <Box alignItems="center" flexDirection="row" gap="space2">
      <PayPalIcon />
      <Text fontSize="87.5">{payPalEmail}</Text>
    </Box>
  );
}
