import useSWR from 'swr';
import { core, isAxiosError } from '@mentimeter/http-clients';
import type { AxiosErrorToJSON } from '@mentimeter/http-clients';

export const useSubscriptions = (skip = false) => {
  const {
    data: subscriptions,
    error,
    isValidating,
  } = useSWR(
    !skip ? '/subscriptions' : null,
    async () => {
      try {
        const response = await core().subscriptions.get();
        return response.data;
      } catch (error) {
        if (isAxiosError(error)) throw error.toJSON();
        throw error;
      }
    },
    { revalidateOnFocus: false },
  );

  return {
    isLoading: !subscriptions && !error,
    isValidating,
    subscriptions,
    error: error as AxiosErrorToJSON,
  };
};
