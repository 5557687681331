import {
  WorkspaceRoleEnum,
  UserFeaturesTypeEnum,
  PlanKeyEnum,
} from '@mentimeter/http-clients';
import { UserLevelEnum, type UserT } from '@mentimeter/user';
import type { WorkspaceT } from '@mentimeter/workspace-data-hooks';

export const userTypes = (
  user: UserT | null,
  workspace: WorkspaceT | undefined,
) => {
  const licenses = workspace?.activeSubscription?.licenses || 0;
  const planKey = workspace?.activeSubscription?.plan.key;

  const userType = getUserType(user?.features.type, licenses, planKey);
  const isBasicUser = userType === UserLevelEnum.BASIC;
  const isFreeUser = userType === UserLevelEnum.FREE;
  const isProUser = userType === UserLevelEnum.PRO;
  const isProMulti = userType === UserLevelEnum.PRO_MULTI;
  const isEnterpriseTrial = userType === UserLevelEnum.ENTERPRISE_TRIAL;
  const isEnterprise = userType === UserLevelEnum.ENTERPRISE;
  const isEnterpriseHigh = userType === UserLevelEnum.ENTERPRISE_HIGH;
  const isAnyEnterprise = isEnterprise || isEnterpriseHigh || isEnterpriseTrial;
  return {
    isBasicUser,
    isFreeUser,
    isProUser,
    isEnterpriseHigh,
    isEnterpriseTrial,
    isEnterprise,
    isProMulti,
    isAnyEnterprise,
  };
};

export const teamAccessLevels = (
  user: UserT | null,
  workspace: WorkspaceT | undefined,
) => {
  const teamAccess = getTeamRole(user, workspace);
  const isAdmin = teamAccess === WorkspaceRoleEnum.ADMIN;
  const isOwner = teamAccess === WorkspaceRoleEnum.OWNER;
  const isMember = teamAccess === WorkspaceRoleEnum.USER;
  const isMemberLite = teamAccess === WorkspaceRoleEnum.MEMBER_LITE;

  return { isMemberLite, isMember, isAdmin, isOwner };
};

export const getUserType = (
  type: string | undefined,
  licenseCount: number,
  planKey?: string,
): UserLevelEnum | null => {
  // Note: Will only happen in testing
  if (!type) return null;
  if (type === UserFeaturesTypeEnum.BASIC) return UserLevelEnum.BASIC;
  if (type === UserFeaturesTypeEnum.REGISTERED) return UserLevelEnum.FREE;
  if (type === UserFeaturesTypeEnum.ENTERPRISE) return UserLevelEnum.ENTERPRISE;
  // For enterprise trial, the 'type' will be 'pro', so
  // we must check the 'planKey' for this special case.
  if (planKey === PlanKeyEnum.ENTERPRISE_TRIAL)
    return UserLevelEnum.ENTERPRISE_TRIAL;
  if (type === UserFeaturesTypeEnum.PRO) {
    if (licenseCount === 1) return UserLevelEnum.PRO;
    if (planKey === PlanKeyEnum.INVOICE && licenseCount >= 50)
      return UserLevelEnum.ENTERPRISE_HIGH;
    return UserLevelEnum.PRO_MULTI;
  }
  throw Error(`unknown user type ${type}`);
};

const getTeamRole = (
  user: UserT | null,
  workspace: WorkspaceT | undefined,
): WorkspaceRoleEnum | null => {
  if (!user?.role) return null;
  if (!workspace?.activeSubscription) return null;

  const { role } = user;
  if (
    [
      WorkspaceRoleEnum.OWNER,
      WorkspaceRoleEnum.ADMIN,
      WorkspaceRoleEnum.USER,
      WorkspaceRoleEnum.MEMBER_LITE,
    ].includes(role)
  )
    return role;

  throw Error(`unknown team role ${role}`);
};
