import { PlanCategory } from '@mentimeter/http-clients';
import type { LocalPricingT } from './types';
import {
  CurrencyCode,
  CurrencySymbol,
  DecimalSeparator,
  ThousandSeparator,
  SymbolPosition,
  SymbolSeparator,
} from './types';

export const INVOICE_CURRENCIES: CurrencyCode[] = [
  CurrencyCode.USD,
  CurrencyCode.EUR,
  CurrencyCode.SEK,
];

export const LOCALIZED_PRICES = {
  GB: {
    currency: {
      code: CurrencyCode.GBP,
      symbol: CurrencySymbol.GBP,
    },
    format: {
      decimalSeparator: DecimalSeparator.POINT,
      thousandSeparator: ThousandSeparator.COMMA,
      symbolPosition: SymbolPosition.LEADING,
    },
    plans: {
      [PlanCategory.BASIC]: { amount: 8.99 },
      [PlanCategory.PRO]: { amount: 21.99 },
      [PlanCategory.EDUCATIONAL_BASIC]: { amount: 6.99 },
      [PlanCategory.EDUCATIONAL_PRO]: { amount: 12.99 },
    },
  } as LocalPricingT,
  EU: {
    currency: {
      code: CurrencyCode.EUR,
      symbol: CurrencySymbol.EUR,
    },
    format: {
      decimalSeparator: DecimalSeparator.COMMA,
      thousandSeparator: ThousandSeparator.POINT,
      symbolPosition: SymbolPosition.LEADING,
    },
    otherCharges: {
      invoiceAdminFee: 5.88,
    },
    plans: {
      [PlanCategory.BASIC]: { amount: 9.99 },
      [PlanCategory.PRO]: { amount: 24.99 },
      [PlanCategory.EDUCATIONAL_BASIC]: { amount: 6.99 },
      [PlanCategory.EDUCATIONAL_PRO]: { amount: 14.99 },
      [PlanCategory.CONFERENCE_SMALL]: { amount: 370 },
      [PlanCategory.CONFERENCE_LARGE]: { amount: 750 },
    },
  } as LocalPricingT,
  SE: {
    currency: {
      code: CurrencyCode.SEK,
      symbol: CurrencySymbol.SEK,
    },
    format: {
      decimalSeparator: DecimalSeparator.COMMA,
      thousandSeparator: ThousandSeparator.SPACE,
      symbolPosition: SymbolPosition.TRAILING,
      symbolSeparator: SymbolSeparator.SPACE,
    },
    otherCharges: {
      invoiceAdminFee: 60,
    },
    plans: {
      [PlanCategory.BASIC]: { amount: 99.99 },
      [PlanCategory.PRO]: { amount: 233.33 },
      [PlanCategory.EDUCATIONAL_BASIC]: { amount: 69.99 },
      [PlanCategory.EDUCATIONAL_PRO]: { amount: 140.0 },
      [PlanCategory.CONFERENCE_SMALL]: { amount: 4000 },
      [PlanCategory.CONFERENCE_LARGE]: { amount: 8000 },
    },
  } as LocalPricingT,
};

export const DEFAULT_PRICE: LocalPricingT = {
  currency: {
    code: CurrencyCode.USD,
    symbol: CurrencySymbol.USD,
  },
  format: {
    decimalSeparator: DecimalSeparator.POINT,
    thousandSeparator: ThousandSeparator.COMMA,
    symbolPosition: SymbolPosition.LEADING,
  },
  otherCharges: {
    invoiceAdminFee: 5.88,
  },
  plans: {
    [PlanCategory.BASIC]: { amount: 11.99, tiers: null },
    [PlanCategory.BASIC_MONTHLY]: { amount: 17.99, tiers: null },
    [PlanCategory.PRO]: { amount: 24.99, tiers: null },
    [PlanCategory.EDUCATIONAL_BASIC]: { amount: 8.99, tiers: null },
    [PlanCategory.EDUCATIONAL_BASIC_MONTHLY]: { amount: 12.99, tiers: null },
    [PlanCategory.EDUCATIONAL_PRO]: { amount: 14.99, tiers: null },
    [PlanCategory.CONFERENCE_SMALL]: { amount: 350, tiers: null },
    [PlanCategory.CONFERENCE_LARGE]: { amount: 750, tiers: null },
  },
};
