import { Box, Skeleton, Text } from '@mentimeter/ragnar-ui';
import { MentimeterSymbol } from '@mentimeter/ragnar-visuals';
import React from 'react';

interface CheckoutHeadingProps {
  heading: string;
  isLoading: boolean;
  subHeading: string;
}

export const CheckoutHeading = ({
  heading,
  subHeading,
  isLoading,
}: CheckoutHeadingProps) => (
  <Box flexDirection="column" flexWrap="wrap">
    <Box flexDirection="row" alignItems="center">
      <MentimeterSymbol width={28} />
      <Text fontSize="175" color="text" ml="space2">
        {heading}
      </Text>
    </Box>
    {isLoading ? (
      <Skeleton
        variant="rectangle"
        width="100%"
        height={16}
        mt="space2"
        mb="space4"
      />
    ) : (
      <Text mb="space4" mt="space1" variant="ragnarBodySm" color="textWeaker">
        {subHeading}
      </Text>
    )}
  </Box>
);
