import { useSubscriptions } from '@mentimeter/billing';
import {
  CheckoutModal,
  useCheckoutModals,
  type HandlePlanClickT,
} from '@mentimeter/checkout-modal';
import { PlanCategory } from '@mentimeter/http-clients';
import { useRouter, useSearchParams } from '@mentimeter/next-navigation';
import { useUser, userPolicies } from '@mentimeter/user';

interface CheckoutModalWrapperPropsT {
  location?: string;
  children: ({
    handlePlanClick,
  }: {
    handlePlanClick: HandlePlanClickT;
  }) => React.ReactNode;
}

export function CheckoutModalsWrapper({
  location,
  children,
}: CheckoutModalWrapperPropsT) {
  const { push } = useRouter();
  const { user } = useUser();
  const { subscriptions } = useSubscriptions(!user);
  const { isEnterpriseUser } = userPolicies(subscriptions);
  const searchParams = useSearchParams();

  // this location prop is used so we only render the modal from the features page since this modal is imported on other places on the plans page as well
  const IsLicenseModalInitiallyOpen =
    searchParams.has('open-licenses-modal') && location === 'features';

  const isCheckoutModalEnabled = !isEnterpriseUser && !user?.isFreeUser;
  const hasPreSelectedPlan =
    (user?.features.type !== undefined &&
      user?.features.type === PlanCategory.BASIC) ||
    user?.features.type === PlanCategory.PRO;

  const {
    handlePlanClick,
    licensesModalOpen,
    planModalOpen,
    billingCycleModalOpen,
    selectedPlan,
    setLicensesModalOpen,
    setPlanModalOpen,
    setBillingCycleModalOpen,
  } = useCheckoutModals({
    enabled: !user ? false : isCheckoutModalEnabled,
    IsLicenseModalInitiallyOpen,
    onDisabledCheckoutModalClick: (href) => {
      push(href ?? '/plans');
    },
  });

  return (
    <>
      {children({ handlePlanClick })}
      <CheckoutModal
        open={planModalOpen}
        onOpenChange={setPlanModalOpen}
        plan={selectedPlan}
        type="plan"
        isCheckoutModalEnabled={isCheckoutModalEnabled}
      />
      <CheckoutModal
        open={licensesModalOpen}
        onOpenChange={setLicensesModalOpen}
        plan={
          hasPreSelectedPlan
            ? (user?.features.type as PlanCategory)
            : selectedPlan
        }
        type="licenses"
        isCheckoutModalEnabled={isCheckoutModalEnabled}
      />
      <CheckoutModal
        open={billingCycleModalOpen}
        onOpenChange={setBillingCycleModalOpen}
        plan={selectedPlan}
        type="billing-cycle"
        isCheckoutModalEnabled={isCheckoutModalEnabled}
      />
    </>
  );
}
