import React from 'react';
import type { PlanT } from '@mentimeter/plans-content';
import type { HandlePlanClickT } from '@mentimeter/checkout-modal';
import { BuyPlanButton } from '../../../components/BuyPlanButton';

export function ActionButton({
  plan,
  onPlanClick,
}: {
  plan: PlanT;
  onPlanClick: HandlePlanClickT | undefined;
}) {
  return (
    <BuyPlanButton
      highlight={plan.highlight}
      disabled={plan.action.disabled}
      onClick={() => {
        onPlanClick?.({
          plan: plan.category,
          isCurrentSubscription: !!plan.isCurrentSubscription,
          isChangingBillingCycle: !!plan.isChangingBillingCycle,
          href: plan.action.href,
        });
        if (plan.track) plan.track();
      }}
    >
      {plan.action.children}
    </BuyPlanButton>
  );
}
