import {
  getEntitlementsRequest,
  getEntitlementsResponse,
} from '@core-api/entitlements/entitlements';
import { captureException, MentiError } from '@mentimeter/errors/sentry';
import { userCache } from '@mentimeter/user';

export async function getClientEntitlements() {
  const region = userCache.region;
  const sessionToken = userCache.getToken();

  const response = await fetch(
    getEntitlementsRequest({ region, userAuth: sessionToken }),
  );

  if (!response.ok && response.status !== 401 && response.status !== 404) {
    const e = new MentiError('Error fetching entitlements', {
      feature: 'entitlements',
    });
    captureException(e);
    throw e;
  }

  const data = await getEntitlementsResponse(response);
  return data;
}
