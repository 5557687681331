import { Link, Text } from '@mentimeter/ragnar-ui';
import { Trans, useTranslations } from '@mentimeter/i18n';
import { ErrorMessageTitle, ErrorMessageWrapper } from './components';

export const GenericUpgradeError = () => {
  const t = useTranslations('checkoutUiPackage');

  return (
    <ErrorMessageWrapper>
      <ErrorMessageTitle text={t('errors.something_went_wrong')} />
      <Text textAlign="center" mt="space4">
        {t('errors.no_process_request')}
        <Trans
          t={t}
          i18nKey="errors.contact_us"
          components={{
            mailToLink: (
              <Link href="mailto:support@mentimeter.com" fontWeight="bold" />
            ),
          }}
        />
      </Text>
    </ErrorMessageWrapper>
  );
};
