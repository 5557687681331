import React from 'react';
import { Box, Skeleton, Text } from '@mentimeter/ragnar-ui';
import { useTranslations } from '@mentimeter/i18n';

interface LicenseSummaryProps {
  currentLicenses: number;
  currentPlan: string | undefined;
  isLoading: boolean;
  children: React.ReactNode;
}

export const LicenseSummary = ({
  currentLicenses,
  currentPlan,
  children,
  isLoading,
}: LicenseSummaryProps) => {
  const t = useTranslations('checkoutUiPackage');

  if (isLoading)
    return <Skeleton variant="rectangle" width={350} height={125} />;

  return (
    <Box
      borderRadius="lg"
      borderColor="borderWeak"
      borderWidth="1px"
      borderStyle="solid"
      py="space6"
      px="space8"
    >
      <Box justifyContent="space-between" gap="space4">
        <Text fontSize="87.5" color="text">
          {t('checkout_modal.license_info', {
            licenses: t('licenses', { count: currentLicenses }),
            currentPlan,
          })}
        </Text>
        <Box>{children}</Box>
      </Box>
    </Box>
  );
};
