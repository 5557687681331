import type { PaymentProcessor } from '@mentimeter/billing';
import type { JSX } from 'react';
import { VisaIcon } from './VisaIcon';
import { MastercardIcon } from './MastercardIcon';
import { AmericanExpressIcon } from './AmericanExpressIcon';
import { DiscoverIcon } from './DiscoverIcon';
import { MaestroIcon } from './MaestroIcon';
import { UnionPayIcon } from './UnionPayIcon';

type Card = { [key in PaymentProcessor]: () => JSX.Element | null };

export const Card: Card = {
  visa: VisaIcon,
  'master-card': MastercardIcon,
  'american-express': AmericanExpressIcon,
  discover: DiscoverIcon,
  maestro: MaestroIcon,
  unionpay: UnionPayIcon,
  unknown: () => null,
  '': () => null,
};
