'use client';
import type { UserResponseT } from '@mentimeter/http-clients';
import React, {
  createContext,
  useContext,
  type PropsWithChildren,
} from 'react';

export interface ServerUserContext {
  initialServerUser: UserResponseT | null;
}

const ServerUserContext = createContext<ServerUserContext | null>(null);

export const ServerUserProvider = ({
  children,
  initialServerUser,
}: PropsWithChildren<ServerUserContext>) => {
  return (
    <ServerUserContext.Provider value={{ initialServerUser }}>
      {children}
    </ServerUserContext.Provider>
  );
};

export const useServerUser = () => {
  // We explicitly allow for application to not have server user contexts
  // until they have servers to actually give them that context.
  return useContext(ServerUserContext);
};
