import { camelCase, isArray, isObject, mapKeys, mapValues } from 'lodash';

type CamelCase<S extends string> =
  S extends `${infer P1}_${infer P2}${infer P3}`
    ? `${P1}${Uppercase<P2>}${CamelCase<P3>}`
    : S;

type CamelifyObject<T> = {
  [K in keyof T as Uncapitalize<CamelCase<string & K>>]: T[K] extends Date
    ? T[K]
    : T[K] extends RegExp
      ? T[K]
      : T[K] extends Array<infer U>
        ? U extends object | undefined
          ? Array<CamelifyObject<U>>
          : T[K]
        : T[K] extends object | undefined
          ? CamelifyObject<T[K]>
          : T[K];
};

export type Camelify<T> = T extends Array<infer U>
  ? Array<CamelifyObject<U>>
  : CamelifyObject<T>;

export function camelify<T extends object>(obj: T): Camelify<T> {
  if (!isObject(obj)) {
    return obj as Camelify<T>;
  }

  if (isArray(obj)) {
    return obj.map((item) => camelify(item)) as Camelify<T>;
  }

  const result = mapKeys(obj, (_, k) => camelCase(k));

  return mapValues(result, (value) => camelify(value as object)) as Camelify<T>;
}
