import {
  WorkspaceRoleEnum,
  type WorkspaceRole,
} from '@mentimeter/http-clients';

const ROLE_NAMES = {
  [WorkspaceRoleEnum.ADMIN]: 'Admin',
  [WorkspaceRoleEnum.OWNER]: 'Owner',
  [WorkspaceRoleEnum.USER]: 'Member',
  [WorkspaceRoleEnum.MEMBER_LITE]: 'Member lite',
  [WorkspaceRoleEnum.NON_MEMBER]: 'Non member',
} as const satisfies Record<WorkspaceRoleEnum, string>;

export const getRoleDisplayName = (role: WorkspaceRole) => {
  return role ? ROLE_NAMES[role] : '';
};
