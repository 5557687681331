import type { ReactElement } from 'react';
import { Badge, Box, Svg, Text } from '@mentimeter/ragnar-ui';
import type { PaymentProcessor } from '@mentimeter/billing';
import { useTranslations } from '@mentimeter/i18n';
import { Card } from '../icons/Card';

interface CreditCardProps {
  lastFourDigits: string;
  type: PaymentProcessor;
  isExpired: boolean;
  expirationDate: string;
  changePlanButton?: (() => ReactElement) | undefined;
}

export function CreditCardPaymentMethod({
  type,
  lastFourDigits,
  isExpired,
  expirationDate,
  changePlanButton,
}: CreditCardProps) {
  const t = useTranslations('checkoutUiPackage');
  const CardComponent = Card[type];

  return (
    <Box flexDirection="row" gap="space2">
      <Svg aria-label={type} viewBox="0 0 100 60" width="34px" height="20px">
        <CardComponent />
      </Svg>
      <Box mx="space2">
        <Box flexDirection="row" gap="space2">
          <Text fontSize="87.5">**** **** **** {lastFourDigits}</Text>
          {changePlanButton && changePlanButton()}
        </Box>

        <Box flexDirection="row" gap="space2" alignItems="center">
          <Text color="textWeaker" fontSize="87.5">
            {t('checkout_modal.payment_method.expires')} {expirationDate}
          </Text>
          {isExpired && (
            <Badge
              type="text"
              text={t('checkout_modal.payment_method.expired').toUpperCase()}
              bg="noticeWeak"
              color="onNoticeWeak"
              compact
            />
          )}
        </Box>
      </Box>
    </Box>
  );
}
