import React from 'react';
import { Box, Button, Text } from '@mentimeter/ragnar-ui';
import { DownloadIcon } from '@mentimeter/ragnar-visuals';
import { useTranslations } from '@mentimeter/i18n';

interface SuccessSummaryProps {
  billingCycle: string;
  invoiceUrl: string;
}

export const BillingCycleSuccessSummary = ({
  billingCycle,
  invoiceUrl,
}: SuccessSummaryProps) => {
  const t = useTranslations('checkoutUiPackage');
  return (
    <Box width="100%" height="100%" justifyContent="center">
      <Text lineHeight="heading" color="text" fontSize={32} fontWeight="bold">
        {t('checkout_modal.success_screens.updated_billing_cycle')}
      </Text>
      <Text mt="space4">
        {t('checkout_modal.success_screens.billing_info', {
          billingCycle: t(billingCycle),
        })}
      </Text>
      <Button
        variant="subtle"
        iconLeading={DownloadIcon}
        href={invoiceUrl ?? '/app/billing'}
        target="_blank"
        rel="noopener noreferrer"
        my="space8"
      >
        {t('checkout_modal.success_screens.download_receipt')}
      </Button>
    </Box>
  );
};
