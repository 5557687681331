export const MastercardIcon = () => (
  <g fill="white" fillRule="evenodd">
    <rect fill="#006" height="60" rx="4" width="100" />
    <path
      d="m56.1286209 30.4854021c0 10.7782557-8.7366506 19.5145979-19.5145978 19.5145979-10.7782557 0-19.5155231-8.7363422-19.5155231-19.5145979 0-10.7779472 8.7372674-19.5152146 19.5155231-19.5152146 10.7779472 0 19.5145978 8.7372674 19.5145978 19.5152146"
      fill="#c00"
    />
    <path
      d="m62.8141062 10.9701875c-5.0435661 0-9.6374857 1.9130024-13.1014294 5.0512761-.7046929.6396207-1.3625092 1.3304356-1.9697479 2.0659685h3.9428882c.5384656.6556575 1.0346805 1.3449304 1.4877194 2.0668938h-6.9186354c-.4138721.6633674-.7901196 1.3526404-1.1244247 2.0668937h9.166868c.313334.6689186.5890431 1.3578832.8265108 2.0659685h-10.8198896c-.2251316.6729278-.4151057 1.3625092-.5668383 2.0656602h11.9529495c.2861947 1.332286.4385441 2.7145328.4385441 4.1325538 0 2.1665068-.3531175 4.2519045-1.0047657 6.1994477h-10.8198896c.2374677.7074685.5131769 1.3973583.825894 2.0672021h9.1674848c-.3349219.7124029-.7093189 1.4022927-1.1256583 2.0665853h-6.916785c.4511886.7198046.9483286 1.4100027 1.4880278 2.064735h3.9416546c-.6060051.7361497-1.2644381 1.4269646-1.9712899 2.0665853 3.4636353 3.1382737 8.0572465 5.0500425 13.1008126 5.0500425 10.7776388 0 19.5145978-8.7363422 19.5145978-19.5145979 0-10.777022-8.736959-19.5152146-19.5145978-19.5152146"
      fill="#f90"
    />
    <g fill="#006">
      <path d="m32.056803 32.6219942c-.2223561-.0252888-.3204271-.0333072-.4730849-.0333072-1.2009078 0-1.8081465.4120218-1.8081465 1.2258882 0 .5011493.296372.819726.7589713.819726.8632103 0 1.4852522-.8215764 1.5222601-2.012307zm1.5410725 3.5869951h-1.756027l.0404004-.8342208c-.5353816.6587414-1.2499434.973309-2.2204767.973309-1.1478631 0-1.9352072-.8962091-1.9352072-2.1985804 0-1.9604959 1.3689856-3.103733 3.7192985-3.103733.2408601 0 .5486428.0222048.8638271.0619883.0653807-.2658404.0826511-.3787146.0826511-.5221204 0-.5329144-.3688458-.7327573-1.3585-.7327573-1.0365308-.0104856-1.8914144.2476448-2.2426814.3629862.0222047-.1338454.2939047-1.8106137.2939047-1.8106137 1.0553433-.3102499 1.7517094-.4262081 2.5353527-.4262081 1.8195573 0 2.7833058.816642 2.781147 2.3604901.0037008.4141806-.0650723.9233482-.1714701 1.5950424-.1838061 1.1657503-.5785576 3.6659454-.6322191 4.2744177z" />
      <path d="m26.8399163 36.2089893h-2.1190132l1.2144774-7.6088336-2.7105236 7.6088336h-1.4436182l-.1785634-7.5662744-1.2755405 7.5662744h-1.983009l1.6564139-9.8987002h3.0466791l.1841145 5.5407062 1.858724-5.5407062h3.3883858z" />
      <path d="m65.4318015 32.6219942c-.2223561-.0252888-.3191935-.0333072-.4715429-.0333072-1.201833 0-1.8084549.4120218-1.8084549 1.2258882 0 .5011493.2951383.819726.7589713.819726.8625935 0 1.4849438-.8215764 1.5210265-2.012307zm1.5419977 3.5869951h-1.7554102l.0397836-.8342208c-.5353816.6587414-1.249635.973309-2.2195515.973309-1.1494051 0-1.935824-.8962091-1.935824-2.1985804 0-1.9604959 1.3696024-3.103733 3.7192985-3.103733.2405516 0 .5477176.0222048.8632103.0619883.0656891-.2658404.0826511-.3787146.0826511-.5221204 0-.5329144-.3688459-.7327573-1.3585-.7327573-1.0365308-.0104856-1.891106.2476448-2.2436067.3629862.0234384-.1338454.2957552-1.8106137.2957552-1.8106137 1.0547264-.3102499 1.7517094-.4262081 2.534119-.4262081 1.8207909 0 2.7833059.816642 2.7814555 2.3604901.0037008.4141806-.0644555.9233482-.1714701 1.5950424-.1831894 1.1657503-.578866 3.6659454-.6319107 4.2744177z" />
      <path d="m43.0133136 36.0865546c-.5800996.1825726-1.0319049.2615229-1.5222602.2615229-1.0827908 0-1.6739927-.6223503-1.6739927-1.7686714-.0151116-.3558931.1557418-1.2918857.290204-2.1455356.122743-.7521865.9190306-5.4935211.9190306-5.4935211h2.1054437l-.2457945 1.2184866h1.2721482l-.28712 1.9348987h-1.2764657c-.2445609 1.530587-.5933607 3.4377297-.5973699 3.6906174 0 .4151057.2214308.5964447.7256641.5964447.2414768 0 .428367-.024672.5711559-.0758663z" />
      <path d="m49.4699664 36.0214823c-.7235053.2211225-1.4207967.3290623-2.1606472.3265952-2.3577145-.003084-3.5866867-1.2335982-3.5866867-3.5913127 0-2.7515407 1.5632773-4.7777257 3.6859913-4.7777257 1.7359811 0 2.8452942 1.1342935 2.8452942 2.9125252 0 .591202-.0758663 1.1672923-.2599808 1.9802335h-4.193617c-.1415554 1.1679091.6053883 1.6542552 1.8312765 1.6542552.7528033 0 1.4331327-.1554334 2.18902-.5060837zm-1.1836375-4.7721745c.0120276-.1680777.2229729-1.4371419-.9807105-1.4371419-.6698438 0-1.1500219.5113265-1.344622 1.4371419z" />
      <path d="m34.8684816 30.7040574c0 1.0174101.4934393 1.7196359 1.6129296 2.2473075.8576592.403695.9911962.5230456.9911962.8884991 0 .5011492-.3787147.7287481-1.2166362.7287481-.6325275 0-1.220337-.0993047-1.8985076-.3185767 0 0-.277868 1.7751477-.2911292 1.8599576.4814117.1042391.9107039.2013849 2.2047484.2380845 2.2352798 0 3.2678015-.8511828 3.2678015-2.6907861 0-1.1059207-.4317593-1.7551018-1.4932706-2.2436066-.8878823-.4073958-.9905793-.4986821-.9905793-.8743127 0-.4351518.3525007-.6575079 1.0368393-.6575079.4160309 0 .9837945.0444096 1.5225685.1205843l.3013064-1.8670509c-.5483344-.087277-1.3803964-.1563585-1.8645837-.1563585-2.3697421 0-3.1903932 1.2376073-3.1826833 2.7250183" />
      <path d="m59.7739035 28.1915264c.5884263 0 1.1379943.1529661 1.8932648.5344564l.3469495-2.1483113c-.3114836-.1221262-1.4035263-.8379215-2.328725-.8379215-1.4174043 0-2.6170785.7043845-3.4593176 1.8642752-1.2298974-.4070874-1.7359811.4157226-2.3552473 1.2360654l-.5504932.1282942c.0416339-.2698496.079567-.5378488.0675395-.8095488h-1.9466179c-.2652237 2.4921767-.7361498 5.0155018-1.1046872 7.5086037l-.0965291.5415496h2.1196301c.3537343-2.2988102.5471008-3.7701844.6649094-4.7669318l.798138-.4422449c.1199674-.4444038.4922057-.5936691 1.2409998-.5754736-.0977627.5248961-.1508074 1.0778564-.1508074 1.6499376 0 2.6340405 1.4229555 4.2738009 3.7026449 4.2738009.5875011 0 1.0920428-.0770999 1.8732188-.2898956l.3713131-2.2556343c-.7025342.3450991-1.2776993.5076257-1.7992029.5076257-1.2317478 0-1.9771495-.9094703-1.9771495-2.4116844 0-2.1800764 1.1077711-3.7069625 2.6901692-3.7069625" />
      <path d="m77.7110377 26.3102891-.4709261 2.859789c-.5782492-.7620553-1.2005994-1.3140905-2.0221758-1.3140905-1.0689128 0-2.0419134.810474-2.6790668 2.0039803-.8872655-.1841146-1.8041374-.4971401-1.8041374-.4971401l-.0006168.00771c.0706235-.6670682.1005383-1.072922.0934451-1.2117018h-1.9463095c-.2642984 2.4921767-.7358413 5.0155018-1.1040704 7.5086037l-.0974542.5415496h2.1202468c.2861948-1.8577989.5048501-3.4022638.666143-4.6253764.724739-.6550406 1.0874168-1.2246546 1.8180153-1.1876466-.3241279.7839516-.5131768 1.6863287-.5131768 2.6121441 0 2.0116902 1.0171017 3.3399671 2.5578658 3.3399671.7765501 0 1.372378-.2679993 1.9534027-.8884991l-.0999214.7487941h2.004597l1.6138548-9.8980834zm-2.6494605 8.0390509c-.7210381 0-1.0861832-.5347648-1.0861832-1.587024 0-1.5823981.6824882-2.7040472 1.6431528-2.7040472.7281313 0 1.1228827.5538856 1.1228827 1.5765384 0 1.5959677-.6938989 2.7145328-1.6798523 2.7145328z" />
    </g>
    <g fill="#fff">
      <path d="m27.4493138 35.6214881h-2.1183964l1.214169-7.6088335-2.7099068 7.6088335h-1.4442351l-.1782549-7.5647323-1.2758489 7.5647323h-1.9830091l1.656414-9.8977749h3.0466791l.0851182 6.1272821 2.0557914-6.1272821h3.2903147z" />
      <path d="m32.6665089 32.0357266c-.2220477-.0259055-.3201187-.0329987-.4727765-.0329987-1.2009078 0-1.8084549.411405-1.8084549 1.2246546 0 .5005324.2966804.8209596.7595881.8209596.8629019 0 1.4849438-.8215764 1.5216433-2.0126155zm1.5407641 3.5857615h-1.7551018l.040092-.8336039c-.5353816.658433-1.2499434.9733089-2.2204767.9733089-1.1481715 0-1.9352072-.8974426-1.9352072-2.1995055 0-1.9608043 1.369294-3.1024994 3.7196069-3.1024994.2402433 0 .548026.0218964.8635187.0626051.0653807-.2664572.0826511-.3796398.0826511-.5236624 0-.532606-.3688458-.7309069-1.3585-.7309069-1.0368392-.012336-1.8914144.2457944-2.2429898.3608274.0225131-.1332286.2939047-1.8099969.2939047-1.8099969 1.0556516-.3096331 1.7520178-.4265166 2.5356611-.4265166 1.8192489 0 2.782689.8175672 2.781147 2.3607985.0033924.4135638-.0647639.9251987-.1714701 1.5950425-.1841145 1.164825-.578866 3.6665621-.6328359 4.2741092z" />
      <path d="m62.6235153 25.9901704-.3469495 2.1480028c-.7565041-.3802566-1.3045301-.534148-1.8923396-.534148-1.5817813 0-2.6914028 1.5287366-2.6914028 3.7091213 0 1.5022142.7457101 2.409834 1.9774578 2.409834.5218121 0 1.0982108-.1612929 1.799203-.5076256l-.3722383 2.2556343c-.7799424.2124872-1.2847925.2902039-1.8719852.2902039-2.2812314 0-3.7020281-1.6400687-3.7020281-4.2734924 0-3.5385764 1.9641967-6.0116323 4.7718661-6.0116323.9251986 0 2.0178582.3919758 2.3284166.514102" />
      <path d="m66.041199 32.0357266c-.2208141-.0259055-.3191935-.0329987-.4715429-.0329987-1.200291 0-1.8081465.411405-1.8081465 1.2246546 0 .5005324.2963719.8209596.7589713.8209596.8625935 0 1.4852521-.8215764 1.5207181-2.0126155zm1.5419977 3.5857615h-1.7551018l.0397836-.8336039c-.5353817.658433-1.2499434.9733089-2.2195516.9733089-1.149405 0-1.9355155-.8974426-1.9355155-2.1995055 0-1.9608043 1.3680604-3.1024994 3.7189901-3.1024994.2402432 0 .5474092.0218964.8625935.0626051.0656891-.2664572.0829595-.3796398.0829595-.5236624 0-.532606-.3679207-.7309069-1.3581916-.7309069-1.035914-.012336-1.8901808.2457944-2.2423731.3608274.0222048-.1332286.2939048-1.8099969.2939048-1.8099969 1.0556516-.3096331 1.751401-.4265166 2.5350442-.4265166 1.8192489 0 2.7836143.8175672 2.7811471 2.3607985.0043176.4135638-.0644555.9251987-.1711617 1.5950425-.1834978 1.164825-.5782492 3.6665621-.6325275 4.2741092z" />
      <path d="m43.6227111 35.4993619c-.5794828.1825725-1.0319049.2618312-1.5216434.2618312-1.0830992 0-1.6746095-.6226587-1.6746095-1.7677462-.0148032-.3571266.1563586-1.2915772.2911292-2.1461524.1218178-.7528033.9184138-5.493521.9184138-5.493521h2.1060605l-.2464113 1.2181782h1.0812488l-.2880451 1.9342819h-1.0846412c-.2445609 1.5321289-.5933607 3.4380381-.5976783 3.6909257 0 .4163394.222356.5964447.7259725.5964447.2414768 0 .4280585-.0237467.5714643-.0761746z" />
      <path d="m50.0805975 35.4355232c-.7238138.2217393-1.4226471.3262867-2.1615724.3256699-2.3580229-.001542-3.5860699-1.2335981-3.5860699-3.5910043 0-2.7524659 1.5629689-4.7786509 3.6841409-4.7786509 1.7378315 0 2.8465278 1.1342936 2.8465278 2.9146841 0 .5899683-.0770999 1.1657503-.2590556 1.9789999h-4.1942338c-.1421722 1.167909.6056967 1.6551803 1.8303513 1.6551803.7534201 0 1.4334411-.1560501 2.1902536-.5076256zm-1.1839459-4.7737165c.0114108-.1683862.2232813-1.4368335-.9800937-1.4368335-.6704606 0-1.1506387.5122517-1.3458556 1.4368335z" />
      <path d="m35.4778791 30.1165563c0 1.0192605.4940561 1.7199442 1.6138548 2.2482327.8576592.4033866.9905794.5227372.9905794.888499 0 .5017661-.3784063.7281314-1.2169446.7281314-.6319107 0-1.2200286-.0980711-1.8985076-.3167264 0 0-.2775596 1.7739142-.2905124 1.8574905.4811033.1054726.9107039.2016933 2.2041316.2390096 2.2358966 0 3.2684183-.8508743 3.2684183-2.6904776 0-1.1065375-.4323762-1.7554102-1.493579-2.2426815-.8881907-.4086294-.9902709-.4989904-.9902709-.8752379 0-.4351517.3521923-.6590498 1.0371477-.6590498.4151057 0 .9834861.0462599 1.5219517.1221262l.3016147-1.8667424c-.5486427-.0866603-1.3800879-.1575922-1.8645836-.1575922-2.3703589 0-3.1903933 1.2379158-3.1833001 2.7250184" />
      <path d="m78.7966041 35.6214881h-2.0039803l.0993047-.7491025c-.5813331.6208083-1.1768527.8888075-1.9534027.8888075-1.5395305 0-2.5581742-1.3267348-2.5581742-3.3405838 0-2.6775249 1.5793141-4.9343927 3.4472901-4.9343927.8215764 0 1.4436182.3355387 2.023101 1.0979024l.4700009-2.8604058h2.0894069zm-3.1256294-1.8599576c.9871869 0 1.6798523-1.1179484 1.6798523-2.712374 0-1.0229613-.394443-1.5786972-1.1225743-1.5786972-.960973 0-1.6431528 1.1219575-1.6431528 2.7049723 0 1.0534929.3654535 1.5860989 1.0858748 1.5860989z" />
      <path d="m69.4915731 27.5719517c-.2649152 2.4915599-.7361497 5.0148849-1.1046872 7.5082952l-.096529.5412412h2.1190132c.7580461-4.9226735.9403102-5.883338 2.1294989-5.7633706.1890489-1.0075413.5415496-1.8898724.8039976-2.3345846-.8872655-.1850397-1.3828636.316418-2.032353 1.2693726.0518111-.4123302.1452562-.8123244.1276774-1.2209538z" />
      <path d="m52.0518873 27.5719517c-.2661488 2.4915599-.7380001 5.0148849-1.1059207 7.5082952l-.0962207.5412412h2.1196301c.7583545-4.9226735.9400018-5.883338 2.12734-5.7633706.1902826-1.0075413.5434-1.8898724.8052312-2.3345846-.8863402-.1850397-1.3828635.316418-2.0311193 1.2693726.0511943-.4123302.1452561-.8123244.1267522-1.2209538z" />
    </g>
  </g>
);
