import useSWR from 'swr';
import { getSplitData, getSplitDataFromLocalstorage } from './get-split-data';

const splitDataFromLocalstorage = getSplitDataFromLocalstorage(['country']);

export function useSplitData() {
  const { data } = useSWR(
    'split-data-country',
    () => getSplitData(['country']),
    { fallbackData: splitDataFromLocalstorage },
  );
  return data;
}
