'use client';

import React, { createContext, useContext } from 'react';
import type { GetEntitlementsResponse } from '@core-api/entitlements/entitlements';

const EntitlementsContext = createContext<GetEntitlementsResponse | null>(null);

export const EntitlementsProvider = ({
  value,
  children,
}: {
  value: GetEntitlementsResponse | null;
  children: React.ReactNode;
}) => {
  return (
    <EntitlementsContext.Provider value={value}>
      {children}
    </EntitlementsContext.Provider>
  );
};

export function useEntitlementsContext() {
  const context = useContext(EntitlementsContext);

  return context;
}
