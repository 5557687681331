import { useMemo } from 'react';
import { useUser } from '@mentimeter/user';
import type { WwwExperiments } from './split-experiments';
// By importing from index, we enable mocking these split functions in tests
import { useSplitData, useSplitsLazy } from './index';

/**
 *
 * @param splitName The string used to identify the experiment in SplitIO.
 * @param enabledVariants An array of all the variants that should be enabled for this specific experiment instance.
 */
export function useExperiment<
  S extends keyof WwwExperiments,
  V extends WwwExperiments[S][number],
>(splitName: S, enabledVariants?: V[]) {
  const { user } = useUser();
  const { country } = useSplitData();
  const lazyTreatment = useSplitsLazy();

  const experimentIsReady = !!user && !!country && lazyTreatment.isReady;

  const splits = useMemo(
    () =>
      experimentIsReady
        ? lazyTreatment.getSplits([splitName], {
            splitAttributes: {
              country,
            },
          })
        : null,
    [lazyTreatment, country, experimentIsReady, splitName],
  );

  const treatment = splits?.[splitName];
  const isInExperiment = !!enabledVariants?.includes(treatment as unknown as V);

  return {
    treatment,
    isInExperiment,
    experimentIsReady,
  };
}
