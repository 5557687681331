export const MaestroIcon = () => (
  <g fill="white" fillRule="evenodd">
    <rect fill="#006" height="60" rx="4" width="100" />
    <path
      d="m83.6564898 29.983733c0 11.0553363-8.9258553 20.016267-19.9391582 20.016267-11.011097 0-19.9382129-8.9609307-19.9382129-20.016267 0-11.0556526 8.9271159-20.01689961 19.9382129-20.01689961 11.0133029 0 19.9391582 8.96124701 19.9391582 20.01689961"
      fill="#c00"
    />
    <path
      d="m48.3148423 42.6950448c-.550539-.6702984-1.0575896-1.3785561-1.5186307-2.1200282h7.0665648c.4241703-.6794719.8070581-1.3874132 1.1489785-2.1197118h-9.3638916c-.3192307-.6857984-.6028513-1.3931071-.8442439-2.1190792h11.0508038c.665563-1.9960277 1.0251309-4.1312396 1.0251309-6.3524926 0-1.4544747-.1544157-2.872888-.4474903-4.238791h-12.2045094c.1544157-.7221761.3491685-1.4288521.578586-2.1197118h11.0470223c-.2423381-.725972-.524383-1.4329644-.8432986-2.1187628h-9.36011c.3419204-.7322986.7257536-1.4389747 1.1486635-2.1200282h7.0621528c-.4607259-.7395741-.969037-1.4465665-1.5186307-2.1178138h-4.0245763c.6192383-.7544416 1.2920494-1.4626993 2.0124457-2.1184465-3.5389548-3.2211649-8.236027-5.18334551-13.3881527-5.18334551-11.0126726 0-19.9397885 8.96124701-19.9397885 20.01689961 0 11.0553363 8.9271159 20.016267 19.9397885 20.016267 5.1524409 0 9.8498282-1.9621806 13.3881527-5.1820801.7213417-.6570127 1.3950982-1.366852 2.0143365-2.1228751z"
      fill="#06c"
    />
    <g fill="#006">
      <path d="m50.0455583 35.3929365c-.8521224.2264906-1.6761978.3372053-2.5472281.3353073-2.7791668-.0025306-4.2265772-1.2732189-4.2265772-3.7032482 0-2.839041 1.8425885-4.9271201 4.343807-4.9271201 2.0458499 0 3.3517652 1.1707288 3.3517652 3.0051131 0 .6086144-.0882375 1.2017288-.3047346 2.0399972h-4.9422464c-.1758448 1.1868615.6873072 1.7078532 2.156777 1.7078532.8801693 0 1.6758826-.1587965 2.5576275-.5146652zm-1.3289201-4.9242731c0-.1790415.2747968-1.4532094-1.1555964-1.4807299-.7897258 0-1.3560216.5270019-1.585124 1.4807299z" />
      <path d="m51.7198652 29.9119266c0 1.0486263.58804 1.773333 1.9251536 2.3126718 1.0219795.4200831 1.1826979.5418693 1.1826979.9173502 0 .5203591-.4512719.7588702-1.4540283.7525436-.7528551-.0053776-1.4401624-.0974289-2.2510021-.3229706l-.3589377 1.9128336c.7210266.16797 1.7316613.223011 2.6275873.2438886 2.6688698 0 3.9010437-.8771767 3.9010437-2.7732448 0-1.140045-.5139835-1.8103434-1.7814524-2.3101411-1.0594805-.4251444-1.1836433-.5165631-1.1836433-.9015339 0-.451716.4197584-.6801045 1.2381614-.6801045.4963361 0 1.1754498.0455511 1.820214.1240004l.3614587-1.9232723c-.6567393-.0914187-1.6528778-.1648067-2.2298882-.1648067-2.8273822 0-3.8058732 1.2776475-3.7973646 2.8127857" />
      <path d="m41.916992 35.5912739h-2.0732666l.049476-.8717991c-.6321588.6876964-1.4745119 1.0081363-2.6171878 1.0081363-1.3525551 0-2.2790491-.9284217-2.2790491-2.2636409 0-2.0295584 1.6106499-3.2002872 4.3800474-3.2002872.284566 0 .646655.0215103 1.0185131.0635819.0768927-.2717255.0976916-.3887667.0976916-.5361754 0-.5554715-.4358304-.7601355-1.6011959-.7601355-1.1489785.0044286-1.9242082.1755619-2.6436591.3694708l.3542106-1.8619048c1.2441491-.317593 2.0584553-.4406445 2.9802222-.4406445 2.1444869 0 3.2770785.844595 3.2770785 2.430662.0179626.4235628-.1285747 1.2732189-.2026312 1.6452203-.0838256.540604-.6781684 3.6773093-.7402497 4.4175161zm-1.8198989-3.7029319c-.2625066-.0259388-.3769002-.033847-.5568418-.033847-1.4140062 0-2.130936.4226137-2.130936 1.2567699 0 .5238386.3497987.8512378.8952956.8512378 1.0159921 0 1.749624-.8531358 1.7924822-2.0741607z" />
      <path d="m61.6607671 35.4596816c-.6800592.1869497-1.2104297.2685622-1.7836584.2685622-1.26999 0-1.9636-.651635-1.9636-1.811925-.040022-.3188583.270385-1.79168.340975-2.201957.0699598-.4115423 1.1700926-6.4110132 1.1700926-6.4110132h2.4674991l-.3731186 1.9852725h1.2655781l-.3438112 2.0254462h-1.2712505s-.6973915 3.5166149-.6973915 3.7854934c0 .4260934.2571493.6114614.8480255.6114614.2823601 0 .500748-.0265715.6693447-.0771839z" />
      <path d="m72.6863602 27.0978755c-1.808554 0-3.2216148.748115-4.0425389 1.9957114l.712518-1.8511496c-1.3128482-.483665-2.1592982.2068783-2.9241284 1.1881268 0 0-.1282595.1629087-.2562039.3118991v-1.454791h-2.3168651c-.3138735 2.5676318-.8688245 5.1716413-1.302764 7.744967l-.1049396.5595837h2.4923948c.2366656-1.3058007.4307881-2.3654984.6242804-3.210726.5294251-2.318682 1.4206241-3.027256 2.7583679-2.7134589-.3088313.6658698-.4780583 1.4377093-.4780583 2.2914778 0 2.0728953 1.1206165 3.768728 3.9048254 3.768728 2.8091044 0 4.8432944-1.5063524 4.8432944-4.9413548 0-2.07163-1.3557065-3.6890135-3.9101827-3.6890135zm-.7254384 6.6156773c-.8801693.0136021-1.4140063-.72787-1.4140063-1.8375476 0-1.3146578.7786961-2.800449 2.0300932-2.800449 1.0096894 0 1.3553914.8037887 1.3553914 1.6600877 0 1.8701293-.7635697 2.9779089-1.9714783 2.9779089z" />
      <path d="m33.8356957 35.5922229h-2.4823104l1.4751422-7.801906-3.3961991 7.801906h-2.2626621l-.4134558-7.7560385-1.4798692 7.7560385h-2.2519476l1.9179055-10.1484248h3.8789844l.3230123 5.6575206 2.4574149-5.6575206h4.1903368z" />
    </g>
    <g fill="#fff">
      <path d="m50.668263 34.7675566c-.851492.226807-1.6758826.3375217-2.5465978.3356237-2.7791667-.0025306-4.2265772-1.2735353-4.2265772-3.7032482 0-2.839041 1.8422734-4.9271201 4.3431768-4.9271201 2.046165 0 3.3523954 1.1707288 3.3523954 3.0047967 0 .6086145-.0885526 1.2004636-.3047345 2.0403136h-4.9419314c-.1761599 1.1855962.6863619 1.7075368 2.1558317 1.7075368.8801693 0 1.6765129-.1587964 2.5579427-.5152978zm-1.32892-4.9239567c0-.1793578.2747968-1.4532094-1.1549661-1.4810463-.7897258 0-1.3560216.527002-1.5857543 1.4810463z" />
      <path d="m52.3432002 29.2862305c0 1.0489426.5877249 1.7730167 1.9245233 2.312988 1.0226099.4203995 1.1830131.5418693 1.1830131.9164013 0 .521308-.4515871.7598191-1.453398.7538089-.7538005-.0056939-1.4411078-.0977453-2.2513173-.3232869l-.3595679 1.9122009c.7213417.1686026 1.7326067.2236436 2.6275873.2448376 2.6688698 0 3.901674-.877493 3.901674-2.7741938 0-1.1394123-.5142987-1.8103434-1.7817676-2.3095084-1.0594805-.4248281-1.1839585-.5175121-1.1839585-.9012176 0-.451716.4203888-.6810535 1.2384766-.6810535.4963361 0 1.1751347.0465001 1.820214.1249494l.3614587-1.9232723c-.6567392-.092684-1.6528778-.1651231-2.2292579-.1651231-2.8280125 0-3.8065035 1.2770149-3.7976798 2.8124695" />
      <path d="m42.540327 34.9658941h-2.0738968l.0501063-.8717991c-.632474.6870637-1.4741969 1.0071873-2.6171879 1.0071873-1.3522399 0-2.2796793-.9281054-2.2796793-2.2633246 0-2.0289257 1.610965-3.1996545 4.3803626-3.1996545.2845659 0 .6463398.0218266 1.0188282.0623165.0768927-.2710928.0973764-.387185.0973764-.5349101 0-.5567367-.4355152-.7604517-1.6005656-.7604517-1.1492937.0047449-1.9245233.1758782-2.6436591.3701034l.3532652-1.8622211c1.2444642-.317593 2.0584553-.4403282 2.9808525-.4403282 2.1444868 0 3.2767633.843646 3.2767633 2.4303456.0185929.4238791-.1288898 1.2719537-.2020009 1.6452203-.0847711.5406041-.6787986 3.6773094-.7405649 4.4175162zm-1.820214-3.7026156c-.2621915-.0272042-.3769002-.0341634-.5571569-.0341634-1.4133759 0-2.1306209.4219812-2.1306209 1.2555046 0 .5247876.350429.8528194.8949805.8528194 1.0166223 0 1.7502542-.8540847 1.7927973-2.0741606z" />
      <path d="m62.283787 34.8343018c-.6803743.1872659-1.2104297.2688785-1.7839735.2688785-1.2699901 0-1.9629697-.6519514-1.9629697-1.8122413-.0403372-.3188583.2700698-1.79168.3403447-2.201957.07059-.4115424 1.1707228-6.4110132 1.1707228-6.4110132h2.4671841l-.3728036 1.9846399h1.2655782l-.3441264 2.0260788h-1.2709354s-.6980218 3.5166149-.6980218 3.7842281c0 .427675.2574645.6117777.8483408.6117777.28236 0 .5004327-.0253062.6699748-.0765513z" />
      <path d="m74.5560502 30.1089988c0 1.8717109-.764515 2.9794905-1.9724237 2.9794905-.8801692.0126531-1.4136911-.72787-1.4136911-1.8375476 0-1.3146578.7786961-2.8007653 2.0297781-2.8007653 1.0100045 0 1.3563367.8037887 1.3563367 1.6588224zm2.6635126.0525104c0-2.0713137-1.3560216-3.6886972-3.9098675-3.6886972-2.9379943 0-4.8382523 1.9656603-4.8382523 4.8616402 0 2.072579 1.1206165 3.7687281 3.9051405 3.7687281 2.8091045 0 4.8429793-1.5063524 4.8429793-4.9416711z" />
      <path d="m64.4818466 26.6616597c-.3135583 2.5682644-.8688244 5.1719576-1.3024488 7.744967l-.1046245.5605327h2.4920797c.8981319-4.9698243 1.1997151-6.3853907 3.0820105-5.8871746l.9053799-2.3512637c-1.3131633-.483665-2.1580376.2071947-2.9228678 1.1890758.0690144-.4419098.1988496-.8686358.1676513-1.2561372z" />
      <path d="m34.4587157 34.9671594h-2.4816803l1.4748271-7.8019061-3.3965142 7.8019061h-2.2626621l-.4137709-7.7563549-1.4798693 7.7563549h-2.2516324l1.9175904-10.1487412h3.8792995l.2048371 6.2829005 2.7306361-6.2829005h4.0352908z" />
    </g>
  </g>
);
