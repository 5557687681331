import React from 'react';
import { clsx } from '@mentimeter/ragnar-tailwind-config';
import { Box } from '../Box';
import { Text } from '../Text';
import type { SkeletonProps } from './Skeleton.types';
import { variants } from './Skeleton.variants';
import './skeleton-keyframes.css';

export const Skeleton = ({
  variant = 'text',
  style,
  fontSize,
  height,
  className,
  ...rest
}: SkeletonProps) => {
  const skeletonClasses = variants({ variant });
  const skeletonGradientClasses = clsx([
    'skeleton-animation',
    'w-full',
    'h-full',
    'bg-gradient-to-r',
    'from-neutral-weakest',
    'via-[theme(backgroundColor.DEFAULT)]',
    'to-neutral-weakest',
    'before:content-zwsp',
  ]);

  const isTextSkeleton = ['text', 'heading'].includes(variant);
  const El = isTextSkeleton ? Text : Box;

  return (
    <El
      {...(Boolean(height) && { height })}
      {...(Boolean(fontSize) && { fontSize })}
      {...rest}
      className={clsx(skeletonClasses, className)}
      style={style}
    >
      <Box as="span" className={skeletonGradientClasses} />
    </El>
  );
};
