import { usePaymentMethod } from '@mentimeter/billing';
import { useUser } from '@mentimeter/user';
import { PaymentMethod } from '@mentimeter/checkout-ui';
import { Box } from '@mentimeter/ragnar-ui';

export const PaymentMethodDataHandler = () => {
  const {
    paymentMethod,
    error: paymentMethodError,
    isLoading: isLoadingPaymentMethod,
  } = usePaymentMethod();

  const { user } = useUser();

  return (
    <Box mt="space1" width="100%">
      <PaymentMethod
        paymentMethod={paymentMethod}
        email={user?.email ?? ''}
        isLoading={isLoadingPaymentMethod}
        hasError={!!paymentMethodError}
        showChangePlanButton={true}
      />
    </Box>
  );
};
