import { snakeCase, isArray, isObject, mapKeys, mapValues } from 'lodash';

type RemoveUnderscoreFirstCharacter<S extends string> =
  S extends `${infer FirstCharacter}${infer U}`
    ? `${FirstCharacter extends '_' ? U : `${FirstCharacter}${U}`}`
    : S;

type SnakeCase<S extends string> = S extends `${infer T}${infer U}`
  ? `${T extends Capitalize<T> ? '_' : ''}${RemoveUnderscoreFirstCharacter<
      Lowercase<T>
    >}${SnakeCase<U>}`
  : S;

type SnakifyObject<T> = {
  [K in keyof T as Uncapitalize<SnakeCase<string & K>>]: T[K] extends Date
    ? T[K]
    : T[K] extends RegExp
      ? T[K]
      : T[K] extends Array<infer U>
        ? U extends object | undefined
          ? Array<SnakifyObject<U>>
          : T[K]
        : T[K] extends object | undefined
          ? SnakifyObject<T[K]>
          : T[K];
};

type Snakify<T> = T extends Array<infer U>
  ? Array<SnakifyObject<U>>
  : SnakifyObject<T>;

export function snakify<T extends object>(obj: T): Snakify<T> {
  if (!isObject(obj)) {
    return obj as Snakify<T>;
  }

  if (isArray(obj)) {
    return obj.map((item) => snakify(item)) as Snakify<T>;
  }

  const result = mapKeys(obj, (_, k) => snakeCase(k));

  return mapValues(result, (value) => snakify(value as object)) as Snakify<T>;
}
