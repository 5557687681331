// This file is automatically generated. DO NOT EDIT.

import { type NumberEntitlement } from '../types/response/NumberEntitlement';
import { type BooleanEntitlement } from '../types/response/BooleanEntitlement';
import { fromSnakeKeys } from '@api/internal';

export interface GetEntitlementsResponse {
  engagedParticipantsLimit: NumberEntitlement;
  excelExport: BooleanEntitlement;
}

export async function getEntitlementsResponse(
  response: Response,
): Promise<GetEntitlementsResponse> {
  const json = await response.json();
  return fromSnakeKeys(json) as GetEntitlementsResponse;
}
