import { useCallback, useState } from 'react';
import { usePaymentMethod } from '@mentimeter/billing';
import { PurchaseSummary } from '@mentimeter/checkout-ui';
import { useUser } from '@mentimeter/user';
import { PaymentMethodType, type PlanCategory } from '@mentimeter/http-clients';
import {
  DefaultPurchaseErrorMessage,
  trackSubmitting,
  updateStripe,
  updateSubscription,
  useUserPlansData,
} from '../data-hooks/hooks';
import type { CheckoutModalType } from '../types';

interface PurchaseSummaryDataHandlerT {
  plan: PlanCategory;
  type: CheckoutModalType;
  numberOfLicenses: number;
  onSubmitSuccess: () => void;
}

export const PurchaseSummaryDataHandler = ({
  plan,
  type,
  numberOfLicenses,
  onSubmitSuccess,
}: PurchaseSummaryDataHandlerT) => {
  const { user } = useUser();
  const { paymentMethod } = usePaymentMethod(!user || user?.isFreeUser);

  const {
    currentPlan,
    newPlan,
    hasTax,
    total,
    progressiveDiscountAmount,
    progressiveDiscountRate,
    taxPercentage,
    tax,
    prorationDate,
    isLoading,
  } = useUserPlansData({ plan, licenses: numberOfLicenses });

  const [purchaseLoading, setPurchaseLoading] = useState<boolean>(false);
  const [purchaseError, setPurchaseError] = useState<string>();

  const submitPurchase = useCallback(async () => {
    if (!paymentMethod) {
      return setPurchaseError('Please add a payment method');
    }
    setPurchaseLoading(true);

    if (
      !currentPlan ||
      !newPlan?.licenses ||
      !newPlan?.category ||
      !currentPlan?.category ||
      !prorationDate
    ) {
      return setPurchaseError(DefaultPurchaseErrorMessage);
    }

    trackSubmitting(newPlan, currentPlan);

    const [errorResponse, successResponse] = await updateSubscription({
      licenses: newPlan.licenses,
      plan_category:
        type === 'licenses' ? currentPlan.category : newPlan.category,
      proration_date: prorationDate,
    });

    if (errorResponse) {
      return setPurchaseError(DefaultPurchaseErrorMessage);
    }

    if (successResponse?.data.payment_intent_client_secret) {
      const errorMessage = await updateStripe(
        successResponse.data.payment_intent_client_secret,
        paymentMethod,
      );

      if (errorMessage) {
        return setPurchaseError(errorMessage);
      }
    }

    setPurchaseLoading(false);
    onSubmitSuccess();
  }, [
    currentPlan,
    newPlan,
    onSubmitSuccess,
    paymentMethod,
    prorationDate,
    type,
  ]);

  return (
    <PurchaseSummary
      paymentMethod={paymentMethod?.type ?? PaymentMethodType.Card}
      isLoading={isLoading}
      newPlan={newPlan}
      currentPlan={currentPlan}
      taxPercentage={taxPercentage}
      hasTax={hasTax}
      tax={tax}
      total={total}
      progressiveDiscountAmount={progressiveDiscountAmount}
      progressiveDiscountRate={progressiveDiscountRate}
      onSubmit={submitPurchase}
      purchaseLoading={purchaseLoading}
      purchaseError={purchaseError ?? ''}
    />
  );
};
