import useSWR from 'swr';
import type {
  AxiosErrorToJSON,
  PaymentMethodResponseT,
} from '@mentimeter/http-clients';
import {
  core,
  isAxiosError,
  PaymentMethodType,
} from '@mentimeter/http-clients';
import { AxiosError } from 'axios';
import type { PaymentMethodT } from '.';

const formatPaymentMethod = (
  paymentMethods: Array<PaymentMethodResponseT> | undefined,
): PaymentMethodT | undefined => {
  // The user might have more than one payment method, we want to grab the last one since that's the most recent one
  const method =
    Array.isArray(paymentMethods) && paymentMethods.length > 0
      ? paymentMethods[paymentMethods.length - 1]
      : undefined;

  if (!method) return undefined;

  switch (method.type) {
    case PaymentMethodType.PayPal:
      return {
        type: PaymentMethodType.PayPal,
        email: method.email ?? '',
      };
    case PaymentMethodType.Link:
      return {
        type: PaymentMethodType.Link,
        email: method.email ?? '',
      };
    case PaymentMethodType.Card:
      return {
        type: PaymentMethodType.Card,
        brand: method.brand ?? '',
        expirationMonth: Number(method.exp_month),
        expirationYear: Number(method.exp_year),
        last4: method.last4 ?? '',
        isExpired: Boolean(method.expired),
      };
    case PaymentMethodType.VirtualAccount:
      return {
        type: PaymentMethodType.VirtualAccount,
        reference: method.reference ?? '',
        iban: method.iban ?? '',
        bic: method.bic ?? '',
        country: method.country ?? '',
      };
    case PaymentMethodType.BankAccount:
      return {
        type: PaymentMethodType.BankAccount,
        reference: method.reference ?? '',
        accountName: method.account_name ?? '',
        bank: method.bank ?? '',
        iban: method.iban ?? '',
        swift: method.swift ?? '',
        address: method.address ?? '',
      };
    default:
      return undefined;
  }
};

export function usePaymentMethod(skip = false) {
  const {
    data: paymentMethods,
    error,
    isLoading,
  } = useSWR(!skip ? 'payment-methods' : null, async () => {
    const response = await core().payments.getPaymentMethods();

    return response.data;
  });

  if (skip) {
    return {
      isLoading: false,
      paymentMethod: undefined,
      error: undefined,
    };
  }

  if (error) {
    const errorObject = isAxiosError(error)
      ? error
      : new AxiosError('Unknown error');

    return {
      isLoading: false,
      paymentMethod: undefined,
      error: errorObject.toJSON() as AxiosErrorToJSON,
    };
  }

  return {
    isLoading,
    paymentMethod: formatPaymentMethod(paymentMethods),
    error: undefined,
  };
}
