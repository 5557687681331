import { Box, Text } from '@mentimeter/ragnar-ui';
import LinkIcon from '../icons/LinkIcon';

interface LinkProps {
  email: string;
}

export function LinkPaymentMethod({ email }: LinkProps) {
  return (
    <Box alignItems="center" flexDirection="row" gap="space2">
      <LinkIcon />
      <Text fontSize="87.5">{email}</Text>
    </Box>
  );
}
