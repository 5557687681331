import debounce from 'lodash/debounce';
import { LicensesSelector } from '@mentimeter/checkout-ui';
import type { TrackCheckoutEventParamsT } from '@mentimeter/billing-address';
import { useLicensesRange } from '../data-hooks/hooks';
import type { CheckoutModalType } from '../types';

interface LicensesSelectorDataHandlerT {
  type: CheckoutModalType;
  onLicensesChange: (licenses: number) => void;
  currentLicensesAmount: number;
  trackCheckoutEvent: (params: TrackCheckoutEventParamsT) => void;
}

export const LicensesSelectorDataHandler = ({
  type,
  onLicensesChange,
  currentLicensesAmount = 0,
  trackCheckoutEvent,
}: LicensesSelectorDataHandlerT) => {
  const licensesRange = useLicensesRange(type);
  const maxLicenses = licensesRange?.up_to
    ? licensesRange?.up_to - currentLicensesAmount
    : undefined;
  return (
    <LicensesSelector
      additionalLicenses={type === 'licenses' ? 1 : 0}
      reservedLicenses={0}
      maxLicenses={maxLicenses ?? 1}
      minLicenses={type === 'licenses' ? 1 : 0}
      onLicensesChange={debounce(onLicensesChange, 600)}
      trackCheckoutEvent={trackCheckoutEvent}
    />
  );
};
