'use client';

import useSWR from 'swr';
import { useEntitlementsContext } from './EntitlementsContext';
import { getClientEntitlements } from './get-client-entitlements';

export function useEntitlements({ skip = false }: { skip?: boolean }) {
  const fallbackEntitlements = useEntitlementsContext();
  const {
    data: entitlements,
    error,
    isLoading,
  } = useSWR(skip ? undefined : 'entitlements', getClientEntitlements, {
    keepPreviousData: true,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    // If we have data from the server already, we don't need to refetch it on mount
    revalidateOnMount: !fallbackEntitlements,
    ...(fallbackEntitlements ? { fallbackData: fallbackEntitlements } : {}),
  });

  return {
    entitlements,
    error,
    isLoading,
  };
}
