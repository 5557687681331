import React, { useEffect } from 'react';
import { PlanCategory } from '@mentimeter/http-clients';
import { SUPPORTED_CHECKOUT_MODAL_PLANS } from '../types';

interface OnPlanClickPropsT {
  plan: PlanCategory;
  isCurrentSubscription: boolean;
  isChangingBillingCycle?: boolean;
  href?: string;
}

interface UseCheckoutModalsPropsT {
  enabled: boolean;
  IsLicenseModalInitiallyOpen?: boolean | undefined;
  onDisabledCheckoutModalClick: (href: string) => void;
}

export type HandlePlanClickT = ({
  plan,
  isCurrentSubscription,
  isChangingBillingCycle,
  href,
}: OnPlanClickPropsT) => void;

export function useCheckoutModals({
  enabled,
  IsLicenseModalInitiallyOpen,
  onDisabledCheckoutModalClick,
}: UseCheckoutModalsPropsT) {
  const [selectedPlan, setSelectedPlan] = React.useState<PlanCategory>(
    PlanCategory.BASIC,
  );
  const [planModalOpen, setPlanModalOpen] = React.useState(false);
  const [licensesModalOpen, setLicensesModalOpen] = React.useState(false);
  const [billingCycleModalOpen, setBillingCycleModalOpen] =
    React.useState(false);

  useEffect(() => {
    if (enabled && IsLicenseModalInitiallyOpen) {
      setLicensesModalOpen(true);
    }
  }, [IsLicenseModalInitiallyOpen, enabled]);

  const handlePlanClick = React.useCallback<HandlePlanClickT>(
    ({
      plan,
      isCurrentSubscription,
      isChangingBillingCycle,
      href = '/plans',
    }) => {
      if (enabled && SUPPORTED_CHECKOUT_MODAL_PLANS.includes(plan)) {
        setSelectedPlan(plan);
        if (isCurrentSubscription && !isChangingBillingCycle) {
          setLicensesModalOpen(true);
        } else if (isChangingBillingCycle) {
          setBillingCycleModalOpen(true);
        } else {
          setPlanModalOpen(true);
        }
      } else {
        onDisabledCheckoutModalClick(href);
      }
    },
    [enabled, onDisabledCheckoutModalClick],
  );
  return {
    licensesModalOpen,
    planModalOpen,
    billingCycleModalOpen,
    selectedPlan,
    handlePlanClick,
    setLicensesModalOpen,
    setPlanModalOpen,
    setBillingCycleModalOpen,
    setSelectedPlan,
  };
}
