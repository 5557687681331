import React from 'react';
import type { MetaT } from '@mentimeter/plans-content';
import { Box } from '@mentimeter/ragnar-ui';
import { H2 } from 'src/ui/typography';
import { MetaText } from '@mentimeter/plans-ui';

export function Heading({
  heading,
  subheading,
}: {
  heading: string;
  subheading: Array<MetaT> | undefined;
}) {
  return (
    <React.Fragment>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <H2 mb="space1" color="text" fontSize={['225', '225', '150']}>
          {heading}
        </H2>
      </Box>
      <Box mt="space2">
        {subheading?.map((item) => (
          <MetaText color="textWeaker" key={item.text} meta={item} />
        ))}
      </Box>
    </React.Fragment>
  );
}
