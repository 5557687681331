import React from 'react';
import type { PlanT } from '@mentimeter/plans-content';
import { Box, Text, Badge } from '@mentimeter/ragnar-ui';
import { greenLight500 } from '@mentimeter/ragnar-colors';

export function DiscountedPrice({
  plan,
  discountPrice,
}: {
  plan: PlanT;
  discountPrice: string;
}) {
  return (
    <Box flexDirection="row" alignItems="center">
      <Text
        color="textWeaker"
        lineHeight="100"
        extend={() => ({ textDecoration: 'line-through' })}
      >
        {discountPrice}
      </Text>
      <Box flexDirection="row" alignItems="center">
        <Badge
          bg={greenLight500}
          px="space2"
          ml="space2"
          type="text"
          color="onPositiveWeakest"
          text={plan.discount!.save}
          compact
        />
      </Box>
    </Box>
  );
}
