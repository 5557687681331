import React from 'react';
import { Box, Skeleton } from '@mentimeter/ragnar-ui';
import { ChevronRightIcon } from '@mentimeter/ragnar-visuals';

interface PlansPreviewProps {
  isLoading: boolean;
  currentPlan: React.ReactNode;
  newPlan: React.ReactNode;
}

export const PlansPreview = ({
  isLoading,
  currentPlan,
  newPlan,
}: PlansPreviewProps) => {
  const skeleton = () => (
    <Skeleton
      variant="rectangle"
      height={[160, 146, 146, 178]}
      width={[140, 236, 205]}
    />
  );
  return (
    <Box flexDirection="row" alignItems="stretch" gap="space2">
      {isLoading ? skeleton() : currentPlan}
      <Box alignSelf="center">
        <ChevronRightIcon size={16} />
      </Box>
      {isLoading ? skeleton() : newPlan}
    </Box>
  );
};
