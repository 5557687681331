import type { UserFlagsT, UserResponseT } from '@mentimeter/http-clients';
import { getNameInitials } from './initials';
import type { UserRequestT, UserT, UserUpdateT } from './types';

export const serverToState = (data: UserResponseT): UserT => {
  return {
    createdAt: data.created_at,
    id: data.id,
    name: data.name,
    address: data.address ?? '',
    email: data.email,
    externalIdentities: data.external_identities ?? [],
    loginMethod: data.saml
      ? 'sso'
      : data.external_identities?.length
        ? 'social'
        : 'password',
    receiveResultsEmail: data.flags?.receive_results_email ?? false,
    aiFeaturesEnabled: data.flags?.ai_features_enabled ?? false,
    optOutCsat: data.flags?.opt_out_csat ?? false,
    onboarding: String(data.flags?.onboarding),
    hasPassword: Boolean(data.has_password),
    hasSso: data.saml,
    initials: getNameInitials(data.name),
    isFreeUser: data.features.type === 'registered',
    features: {
      ...data.features,
      series_in_workspace_limit:
        data.features.series_in_workspace_limit === -1
          ? Infinity
          : data.features.series_in_workspace_limit,
    },
    region: data.region,
    payments_provider: data.payments_provider ?? 'unknown',
    role: data.role,
    ownerOrAdmin: ['admin', 'owner'].includes(data.role ?? ''),
    workspaceId: data.active_workspace_id,
    betaStatus: data.beta_status,
    intercomHash: data.intercom_hash ?? '',
    flags: data.flags,
    collaboration_rights: data.collaboration_rights,
    profile_picture: data.profile_picture,
    service_urls: data.service_urls,
    isVerified: data.is_verified,
    requiresEmailVerification: data.requires_email_verification,
    token: data.token,
  };
};

export const stateToServer = (data: UserUpdateT): UserRequestT => {
  let flags: UserFlagsT = {};

  if (data.receiveResultsEmail !== undefined) {
    flags = {
      receive_results_email: data.receiveResultsEmail,
    };
  }
  if (data.aiFeaturesEnabled !== undefined) {
    flags = {
      ai_features_enabled: data.aiFeaturesEnabled,
    };
  }
  if (data.optOutCsat !== undefined) {
    flags = {
      opt_out_csat: data.optOutCsat,
    };
  }

  if (data.flags?.role_changed !== undefined) {
    flags = {
      role_changed: data.flags.role_changed,
    };
  }

  const res = {
    name: data.name,
    address: data.address,
    email: data.email,
    flags,
    password: data.password,
    current_password: data.currentPassword,
    beta_status: data.betaStatus,
  } as UserRequestT;
  // @ts-expect-error-auto TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  Object.keys(res).forEach((key) => res[key] === undefined && delete res[key]);

  return res;
};
