import { PlanCategory } from '@mentimeter/http-clients';

export type CheckoutModalType = 'plan' | 'licenses' | 'billing-cycle';

export const SUPPORTED_CHECKOUT_MODAL_PLANS = [
  PlanCategory.BASIC,
  PlanCategory.BASIC_MONTHLY,
  PlanCategory.EDUCATIONAL_BASIC,
  PlanCategory.EDUCATIONAL_BASIC_MONTHLY,
  PlanCategory.PRO,
  PlanCategory.EDUCATIONAL_PRO,
];
