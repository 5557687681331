'use client';

import { type GetEntitlementsResponse } from '@core-api/entitlements/entitlements';
import { useUser } from '@mentimeter/user';
import { useEntitlements } from './use-entitlements';

type Entitlement = keyof GetEntitlementsResponse;

export function useEntitlement<E extends Entitlement>(
  entitlement: E,
): GetEntitlementsResponse[E] | null {
  const { isAuthenticated } = useUser();
  const { entitlements, isLoading, error } = useEntitlements({
    skip: !isAuthenticated,
  });

  if (isLoading || error || !entitlements) {
    return null;
  }

  return entitlements[entitlement];
}
