// This file is automatically generated. DO NOT EDIT.

import { type GracePeriod } from '../../types/response/GracePeriod';
import { fromSnakeKeys } from '@api/internal';

export interface GetEngagedParticipantsResponse {
  /** The limit of engaged participants the user can use per month */
  engagedParticipantsLimit: number;
  /** The number of engaged participants since start of this month */
  engagedParticipants: number;
  /** The number of engaged participants since start of this month */
  engagementStatus:
    | 'Unengaged'
    | 'ActivatedLimit'
    | 'NearingLimit'
    | 'ReachedLimit';
  /** When the engaged participants limit next resets, start of next month */
  resetsAt: string;
  /** Details about the grace period */
  gracePeriod: GracePeriod | null;
}

export async function getEngagedParticipantsResponse(
  response: Response,
): Promise<GetEngagedParticipantsResponse> {
  const json = await response.json();
  return fromSnakeKeys(json) as GetEngagedParticipantsResponse;
}
