import type {
  CurrencyCode,
  CurrencySymbol,
  DecimalSeparator,
  LocalPricingT,
  ThousandSeparator,
} from '@mentimeter/billing';
import { PlanCategory, type PricesResponseT } from '@mentimeter/http-clients';
import _ from 'lodash';

function mapTiers(
  tiers: PricesResponseT['tiers'] | null | undefined,
  subunitToUnit: PricesResponseT['currency']['subunit_to_unit'] | undefined,
) {
  if (tiers === null || tiers === undefined || subunitToUnit === undefined)
    return null;
  return tiers.map((tier) => ({
    unitAmount: tier.unit_amount / subunitToUnit / 12,
    upTo: tier.up_to,
  }));
}

function calculateAmount(
  amount: PricesResponseT['amount'] | undefined,
  subunitToUnit: PricesResponseT['currency']['subunit_to_unit'] | undefined,
  monthly?: boolean,
) {
  if (
    amount === undefined ||
    amount === null ||
    subunitToUnit === undefined ||
    subunitToUnit === null
  )
    return null;

  if (monthly) return amount / subunitToUnit / 12;
  return amount / subunitToUnit;
}

export function formatPriceData(priceList: PricesResponseT[]): LocalPricingT {
  const proPlan = _.find(priceList, {
    legacy_plan_category: PlanCategory.PRO,
  });
  const proPlanEducational = _.find(priceList, {
    legacy_plan_category: PlanCategory.EDUCATIONAL_PRO,
  });
  const basicPlanMonthly = _.find(priceList, {
    legacy_plan_category: PlanCategory.BASIC_MONTHLY,
  });
  const basicPlan = _.find(priceList, {
    legacy_plan_category: PlanCategory.BASIC,
  });
  const basicPlanEducational = _.find(priceList, {
    legacy_plan_category: PlanCategory.EDUCATIONAL_BASIC,
  });
  const basicPlanEducationalMonthly = _.find(priceList, {
    legacy_plan_category: PlanCategory.EDUCATIONAL_BASIC_MONTHLY,
  });
  const conferenceSmallPlan = _.find(priceList, {
    legacy_plan_category: PlanCategory.CONFERENCE_SMALL,
  });
  const conferenceLargePlan = _.find(priceList, {
    legacy_plan_category: PlanCategory.CONFERENCE_LARGE,
  });

  return {
    currency: {
      code: proPlan?.currency?.iso_code as CurrencyCode,
      symbol: proPlan?.currency?.symbol as CurrencySymbol,
    },
    format: {
      decimalSeparator: proPlan?.currency?.decimal_mark as DecimalSeparator,
      symbolPosition: proPlan?.currency?.symbol_first ? 0 : 1,
      thousandSeparator: proPlan?.currency
        ?.thousands_separator as ThousandSeparator,
    },
    otherCharges: {},
    plans: {
      pro: {
        amount: calculateAmount(
          proPlan?.amount,
          proPlan?.currency?.subunit_to_unit,
          true,
        ),
        tiers: mapTiers(proPlan?.tiers, proPlan?.currency?.subunit_to_unit),
      },
      'educational-pro': {
        amount: calculateAmount(
          proPlanEducational?.amount,
          proPlanEducational?.currency?.subunit_to_unit,
          true,
        ),
        tiers: mapTiers(
          proPlanEducational?.tiers,
          proPlanEducational?.currency?.subunit_to_unit,
        ),
      },
      basic: {
        amount: calculateAmount(
          basicPlan?.amount,
          basicPlan?.currency?.subunit_to_unit,
          true,
        ),
        tiers: mapTiers(basicPlan?.tiers, proPlan?.currency?.subunit_to_unit),
      },
      'basic-monthly': {
        amount: calculateAmount(
          basicPlanMonthly?.amount,
          basicPlanMonthly?.currency?.subunit_to_unit,
        ),
        tiers: mapTiers(
          basicPlanMonthly?.tiers,
          basicPlanMonthly?.currency?.subunit_to_unit,
        ),
      },
      'educational-basic': {
        amount: calculateAmount(
          basicPlanEducational?.amount,
          basicPlanEducational?.currency?.subunit_to_unit,
          true,
        ),
        tiers: mapTiers(
          basicPlanEducational?.tiers,
          basicPlanEducational?.currency?.subunit_to_unit,
        ),
      },
      'educational-basic-monthly': {
        amount: calculateAmount(
          basicPlanEducationalMonthly?.amount,
          basicPlanEducationalMonthly?.currency?.subunit_to_unit,
        ),
        tiers: mapTiers(
          basicPlanEducationalMonthly?.tiers,
          basicPlanEducationalMonthly?.currency?.subunit_to_unit,
        ),
      },
      'conference-large': {
        amount: calculateAmount(
          conferenceLargePlan?.amount,
          conferenceLargePlan?.currency?.subunit_to_unit,
        ),
        tiers: mapTiers(
          conferenceLargePlan?.tiers,
          conferenceLargePlan?.currency?.subunit_to_unit,
        ),
      },
      'conference-small': {
        amount: calculateAmount(
          conferenceSmallPlan?.amount,
          conferenceSmallPlan?.currency?.subunit_to_unit,
        ),
        tiers: mapTiers(
          conferenceSmallPlan?.tiers,
          conferenceSmallPlan?.currency?.subunit_to_unit,
        ),
      },
    },
  };
}
