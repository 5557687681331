import type {
  PlanCategory,
  SubscriptionsResponseT,
} from '@mentimeter/http-clients';

/**
 *
 * @param subscriptions The user's subscription we want to derive the policies from
 * @returns A set of booleans that define the user type.
 * Explicit user types are mapped one-to-one to the user's subscription.
 * Derived user types are a superset of other users types. For example, a GenericBasicYearlyUser can be either a BasicYearlyUser or an EducationalBasicYearlyUser
 */
export const userPolicies = (
  subscriptions: SubscriptionsResponseT | undefined,
) => {
  const planCategory = subscriptions?.current_subscription?.plan
    .category as PlanCategory;
  // Explicit user types
  const isProTrialUser = planCategory === 'pro-trial';
  const isEnterpriseUser = planCategory === 'enterprise';
  const isEnterpriseTrialUser = planCategory === 'enterprise-trial';
  const isProYearlyUser = planCategory === 'pro';
  const isLegacyTeamsUser = planCategory === 'teams';
  const isEducationalYearlyProUser = planCategory === 'educational-pro';
  const isBasicYearlyUser = planCategory === 'basic';
  const isEducationalBasic = planCategory === 'educational-basic';
  const isBasicMonthlyUser = planCategory === 'basic-monthly';
  const isEducationalBasicMonthlyUser =
    planCategory === 'educational-basic-monthly';
  const isEducationalBasicYearlyUser = planCategory === 'educational-basic';

  // Derived user types
  const isTrialUser = isProTrialUser || isEnterpriseTrialUser;
  const isGenericBasicYearlyUser =
    isBasicYearlyUser || isEducationalBasicYearlyUser;
  const isGenericBasicMonthlyUser =
    isBasicMonthlyUser || isEducationalBasicMonthlyUser;
  const isGenericBasicUser =
    isGenericBasicMonthlyUser || isGenericBasicYearlyUser;
  const isGenericProUser =
    isProYearlyUser || isLegacyTeamsUser || isEducationalYearlyProUser;

  return {
    isTrialUser,
    isGenericProUser,
    isGenericBasicUser,
    isGenericBasicMonthlyUser,
    isGenericBasicYearlyUser,
    isBasicMonthlyUser,
    isBasicYearlyUser,
    isEducationalBasic,
    isEducationalBasicMonthlyUser,
    isEducationalBasicYearlyUser,
    isProYearlyUser,
    isLegacyTeamsUser,
    isEnterpriseUser,
  };
};

export type UserPoliciesT = ReturnType<typeof userPolicies>;
