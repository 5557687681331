import { FormattedPlanNames } from '@mentimeter/billing';

export function getPlanNameTitle(
  plan: FormattedPlanNames,
  isInExperiment: boolean,
): string {
  if (isInExperiment) {
    switch (plan) {
      case FormattedPlanNames.pro:
        return 'Mentimeter Business';
      case FormattedPlanNames.basic:
        return 'Mentimeter Essential';
      case FormattedPlanNames['basic-monthly']:
        return 'Mentimeter Essential Monthly';
      case FormattedPlanNames['educational-basic']:
        return 'Mentimeter Educational Essential';
      case FormattedPlanNames['educational-basic-monthly']:
        return 'Mentimeter Educational Essential Monthly';
      default:
        return plan;
    }
  } else {
    return plan ?? 'Mentimeter';
  }
}
