export const getNameInitials = (
  name?: string | null,
  singleLetter?: boolean,
): string => {
  if (!name) {
    return '';
  }
  return name
    .split(' ')
    .filter((word) => word.length > 0)
    .slice(0, singleLetter ? 1 : 2)
    .map((word) => String.fromCodePoint(word.codePointAt(0)!))
    .join('');
};
