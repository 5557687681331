export const VisaIcon = () => (
  <g fill="white" fillRule="evenodd">
    <rect fill="#26337a" height="60" rx="4" width="100" />
    <g fill="#fffffe">
      <path d="m41.4899908 39.5467606 3.0747373-19.0740845h4.9148572l-3.0744563 19.0740845z" />
      <path d="m64.232871 20.9408451c-.9717834-.3828169-2.5001567-.8002817-4.4040876-.8002817-4.8591981 0-8.2796958 2.5884507-8.3080875 6.2949296-.0309217 2.7416901 2.4408433 4.2707042 4.3042949 5.1819718 1.9151751.9340845 2.5586267 1.531831 2.5510368 2.3670422-.0132119 1.2774648-1.5286543 1.8628169-2.9417741 1.8628169-1.967742 0-3.0131752-.288169-4.6295346-1.0002816l-.6322074-.303662-.6873041 4.2695774c1.1460691.5312677 3.2706682.9923944 5.4756636 1.0166198 5.1641981 0 8.5197603-2.5571831 8.5591152-6.5152113.0177097-2.1740845-1.2919632-3.8219718-4.1283226-5.1825352-1.7175576-.8845071-2.7717051-1.4704225-2.7596175-2.3656338.0011244-.7926761.8905438-1.6414085 2.8155576-1.6414085 1.6076451-.0259155 2.7714239.3453521 3.6782719.7329578l.4413364.2180281z" />
      <path d="m70.7772995 32.7842254c.4073226-1.0963381 1.9579033-5.3315493 1.9579033-5.3315493-.0286728.0504225.4050737-1.1047888.6532903-1.8197184l.3317051 1.6450705s.9431106 4.552676 1.1373548 5.5061972zm6.0634563-12.2921127h-3.7983042c-1.1781152 0-2.0599447.3374648-2.5760553 1.5811267l-7.298917 17.4802817h5.1622304s.8421935-2.3515493 1.0333456-2.8676056c.5630553 0 5.5791106.0098591 6.2950876.0098591.1472995.6656339.5984746 2.8577465.5984746 2.8577465h4.561788z" />
      <path d="m37.3667281 20.4887324-4.8105668 13.0039437-.5152673-2.6433803c-.896447-3.0456338-3.6869862-6.3467606-6.8083871-8.0008451l4.4009954 16.6816901 5.2015853-.0030985 7.7399723-19.0383099z" />
    </g>
    <path
      d="m28.0905115 20.4757746h-7.9257834l-.0652166.3952113c6.1671844 1.58 10.2477189 5.3938028 11.9413825 9.9783099l-1.7223364-8.7642254c-.297129-1.2087324-1.1606866-1.5670422-2.2280461-1.6092958"
      fill="#ec982d"
    />
  </g>
);
