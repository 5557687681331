import { Box, Clickable, TextInputItem } from '@mentimeter/ragnar-ui';
import { MinusIcon, PlusIcon } from '@mentimeter/ragnar-visuals';
import React, { useEffect, useState } from 'react';
import type { TrackCheckoutEventParamsT } from '@mentimeter/billing-address';

interface LicensesButtonProps {
  type: 'increase' | 'decrease';
  onClick: () => void;
}

const LicensesButton = ({ type, onClick }: LicensesButtonProps) => (
  <Clickable onClick={onClick}>
    <Box
      width={32}
      height={32}
      alignItems="center"
      justifyContent="center"
      borderRadius={3}
      bg="buttonBgTertiary"
    >
      {type === 'decrease' && <MinusIcon />}
      {type === 'increase' && <PlusIcon />}
    </Box>
  </Clickable>
);

interface LicensesSelectorProps {
  minLicenses: number;
  maxLicenses: number;
  additionalLicenses: number;
  reservedLicenses: number;
  onLicensesChange?: (value: number) => void;
  trackCheckoutEvent: (params: TrackCheckoutEventParamsT) => void;
}

export const LicensesSelector = ({
  minLicenses,
  maxLicenses,
  additionalLicenses,
  reservedLicenses,
  onLicensesChange,
  trackCheckoutEvent,
}: LicensesSelectorProps) => {
  const [licenseAmount, setLicenseAmount] =
    useState<number>(additionalLicenses);
  const totalAmount = reservedLicenses + licenseAmount;

  const increase = () => {
    const underMaxLicenses = !maxLicenses || totalAmount < maxLicenses;
    const newLicenseAmount = underMaxLicenses
      ? licenseAmount + 1
      : licenseAmount;
    setLicenseAmount(newLicenseAmount);

    trackCheckoutEvent({
      event: 'Edited license amount',
      additionalProperties: {
        licenses: newLicenseAmount,
        version: 'v0',
      },
    });
  };

  const decrease = () => {
    const newLicenseAmount =
      totalAmount > minLicenses ? licenseAmount - 1 : licenseAmount;
    trackCheckoutEvent({
      event: 'Edited license amount',
      additionalProperties: {
        licenses: newLicenseAmount,
        version: 'v0',
      },
    });
    setLicenseAmount(newLicenseAmount);
  };

  const setLicenses = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const exclude = /[-+e]/;
    const newLicenseAmount = Number(inputValue);

    if (
      !exclude.test(inputValue) &&
      newLicenseAmount > 0 &&
      (!maxLicenses || newLicenseAmount <= maxLicenses)
    ) {
      setLicenseAmount(newLicenseAmount);
      trackCheckoutEvent({
        event: 'Edited license amount',
        additionalProperties: {
          licenses: newLicenseAmount,
          version: 'v0',
        },
      });
    }
  };

  useEffect(() => {
    onLicensesChange?.(licenseAmount);
  }, [onLicensesChange, licenseAmount]);

  return (
    <Box mt={2} flexDirection="row" alignItems="center" gap="space2">
      <LicensesButton type="decrease" onClick={decrease} />
      <Box width={57}>
        <TextInputItem
          type="number"
          inputSize="compact"
          autoComplete="off"
          name="number-of-licenses"
          id="number-of-licenses"
          value={reservedLicenses + licenseAmount}
          onChange={setLicenses}
          extend={({ theme }) => ({
            borderColor: theme.colors.border,
            backgroundColor: theme.colors.bg,
          })}
        />
      </Box>
      <LicensesButton type="increase" onClick={increase} />
    </Box>
  );
};
