import React, { useCallback } from 'react';
import { Box, Button, Text } from '@mentimeter/ragnar-ui';
import { usePathname } from '@mentimeter/next-navigation';
import { DownloadIcon } from '@mentimeter/ragnar-visuals';
import { useTranslations } from '@mentimeter/i18n';

interface SuccessSummaryProps {
  addedLicenses: number;
  handleCloseModal: () => void;
  invoiceUrl?: string;
}

export const LicensesSuccessSummary = ({
  addedLicenses,
  invoiceUrl,
  handleCloseModal,
}: SuccessSummaryProps) => {
  const t = useTranslations('checkoutUiPackage');
  const pathname = usePathname();

  const handleClickSetupTeamLink = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      if (pathname === '/app/manage-members') {
        e.preventDefault();
        handleCloseModal();
      }
    },
    [handleCloseModal, pathname],
  );

  return (
    <Box width="100%" height="100%" justifyContent="center">
      <Box>
        <Text lineHeight="heading" color="text" fontSize={32} fontWeight="bold">
          {t('checkout_modal.success_screens.licenses_success', {
            licenses: t('licenses', { count: addedLicenses }),
          })}
        </Text>
      </Box>
      <Box mt="space8">
        <Button
          variant="primary"
          href="/app/manage-members"
          onClick={handleClickSetupTeamLink}
        >
          {t('checkout_modal.success_screens.setup_team_CTA')}
        </Button>
      </Box>
      <Box my="space4">
        <Button
          variant="subtle"
          iconLeading={DownloadIcon}
          href={invoiceUrl ?? '/app/billing'}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('checkout_modal.success_screens.download_receipt')}
        </Button>
      </Box>
    </Box>
  );
};
