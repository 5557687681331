import { PlanCategory } from '@mentimeter/http-clients';
import { type Dispatch, type SetStateAction } from 'react';
import { PaymentProcessor } from './types';

export const isConferencePlan = (planCategory: PlanCategory) =>
  planCategory === PlanCategory.CONFERENCE_SMALL ||
  planCategory === PlanCategory.CONFERENCE_LARGE;

export const isString = (string: unknown): string is string =>
  typeof string === 'string';

// amount always comes with cents included
export const roundUpAmount = (amount: number) => {
  return Math.ceil(amount / 100);
};

export const calculateDaysUntilSubscriptionEnds = (periodEndDate?: number) => {
  const SECONDS_PER_DAY = 86400;
  return (
    periodEndDate &&
    Math.floor((periodEndDate - new Date().getTime() / 1000) / SECONDS_PER_DAY)
  );
};

export const isChangingBillingCycle = (
  currentPlanCycle: string,
  newPlanCycle: string,
) => {
  return currentPlanCycle === 'month' && newPlanCycle === 'year';
};

export const formatDiscountPercentage = (
  currentMonthlyPrice: number | undefined,
  newYearlyPrice: number | undefined,
  currentPlanCycle: string,
  newPlanCycle: string,
) => {
  if (!currentMonthlyPrice || !newYearlyPrice) return 0;

  if (isChangingBillingCycle(currentPlanCycle, newPlanCycle)) {
    const oldYearly = currentMonthlyPrice * 12;
    const percentage_diff = (oldYearly - newYearlyPrice) * 100;
    return Math.round(percentage_diff / oldYearly);
  } else {
    return 0;
  }
};

export const formatPlanCategory = (category?: PlanCategory) => {
  switch (category) {
    case 'basic':
    case 'basic-monthly':
      return 'Basic';
    case 'educational-basic':
    case 'educational-basic-monthly':
      return 'Educational Basic';
    case 'pro':
      return 'Pro';
    case 'educational-pro':
      return 'Educational Pro';
    case 'pro-trial':
      return 'Pro Trial';
    case 'teams':
      return 'Invoice';
    case 'enterprise':
      return 'Enterprise';
    case 'enterprise-trial':
      return 'Enterprise Trial';
    case 'conference-small':
      return 'Conference Small';
    case 'conference-large':
      return 'Conference Large';
    default:
      return '';
  }
};

export const formatPercentage = (part: number | undefined, total: number) => {
  if (part === undefined || part === null) return undefined;
  return parseFloat(((part / total) * 100).toFixed(2)) + '%';
};

export const calculateAndFormatAmountPerPeriod = ({
  amount,
  currency,
  subUnit,
  periodToCalculate,
  locale,
}: {
  amount: number | undefined;
  currency: string | undefined;
  subUnit: number | undefined;
  periodToCalculate: 'month' | 'year' | undefined;
  locale?: Intl.LocalesArgument;
}): string | undefined => {
  if (amount === undefined || amount === null || !currency || !subUnit) {
    return undefined;
  }

  const perLicenseAmount = amount / subUnit;

  // For the yearly plans we want to show the monthly cost, so we need to divide by 12
  // For the other plans (monthly plans, and conference plans) we get the price as we want to show it
  const calculatedAmount =
    periodToCalculate === 'year' ? perLicenseAmount / 12 : perLicenseAmount;

  return formatCurrency({ amount: calculatedAmount, currency, locale });
};

export const formatAmount = ({
  amount,
  currency,
  subUnit,
  locale,
}: {
  amount: number | undefined;
  currency: string | undefined;
  subUnit: number | undefined;
  locale?: Intl.LocalesArgument;
}): string | undefined => {
  if (amount === undefined || amount === null || !currency || !subUnit) {
    return undefined;
  }
  return formatCurrency({ amount: amount / subUnit, currency, locale });
};

export const formatCurrency = ({
  amount,
  currency,
  locale,
}: {
  amount: number | undefined;
  currency: string | undefined;
  locale?: Intl.LocalesArgument;
}): string | undefined => {
  return amount?.toLocaleString(locale ?? 'en-US', {
    style: 'currency',
    currency,
  });
};

export function formatDate(
  date: number | undefined | null,
  addDays: number = 0,
  locale: Intl.LocalesArgument = 'en-US',
) {
  if (!date) return '';

  const timestamp = new Date(date * 1000);
  timestamp.setDate(timestamp.getDate() + addDays);
  return new Intl.DateTimeFormat(locale, { dateStyle: 'medium' }).format(
    timestamp,
  );
}

export function formatPaymentMethodEmail(account?: string) {
  return account?.replace(/\*/g, '•') ?? '';
}

export function mapCreditCardType(creditCardType?: unknown): PaymentProcessor {
  if (!creditCardType || !isString(creditCardType))
    return PaymentProcessor.OTHER;
  const type = creditCardType.toLowerCase();
  switch (type) {
    case 'visa':
      return PaymentProcessor.VISA;
    case 'mastercard':
      return PaymentProcessor.MASTERCARD;
    case 'amex':
    case 'american express':
      return PaymentProcessor.AMERICAN_EXPRESS;
    case 'discover':
      return PaymentProcessor.DISCOVER;
    case 'maestro':
      return PaymentProcessor.MAESTRO;
    case 'unionpay':
      return PaymentProcessor.UNION_PAY;
    case 'unknown':
    default:
      return PaymentProcessor.OTHER;
  }
}
/** useEffect to ensure that the fixed sidebar is scrollable once the payment button goes out of view
 *
 * @param elementRef A React ref object pointing to the HTML element to observe.
 * @param setIsButtonInView A state setter function for updating the button's visibility.
 * @param setHasButtonBeenOutOfView A state setter function for indicating if the button has been out of view.
 * @returns The created IntersectionObserver instance for potential further use.
 */
export function paymentElementVisibilityObserver(
  elementRef: React.RefObject<HTMLElement>,
  setIsButtonInView: Dispatch<SetStateAction<boolean>>,
  setHasButtonBeenOutOfView: Dispatch<SetStateAction<boolean>>,
) {
  const observer = new IntersectionObserver(
    (entries) => {
      const entry = entries[0];
      if (!entry) return;
      setIsButtonInView(entry.isIntersecting); // Update visibility state
      if (!entry.isIntersecting) {
        setHasButtonBeenOutOfView(true); // Update state when button goes out of view
      }
    },
    {
      root: null,
      threshold: 0.1, // Callback will run when 10% of the button is visible
    },
  );

  if (elementRef.current) {
    observer.observe(elementRef.current); // Start observing the button
  }

  return observer;
}
