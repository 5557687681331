import { useState } from 'react';
import * as Yup from 'yup';
import { Form, TextInput, Textarea, Select } from '@mentimeter/ragnar-form';
import {
  Text,
  Box,
  Button,
  CharacterCount,
  CharacterCountWrap,
  ModalBody,
  ModalContainer,
  ModalHeader,
  ModalRoot,
  useButtonState,
} from '@mentimeter/ragnar-ui';
import { tracking } from '@mentimeter/http-clients';
import { useUser } from '@mentimeter/user';
import { useToast } from '@mentimeter/toast';
import { trackUser } from '@api/tracking/client';

const SELECT_DEFAULT_VALUE = {
  key: 'defaultValue',
  value: '',
  label: '- select -',
  disabled: true,
};

interface PropsT {
  trackingContext: string;
  showSalesModal: boolean;
  setShowSalesModal: (showSalesModal: boolean) => void;
  subHeading?: string;
}

interface FormValuesT {
  name: string;
  email: string;
  licensesNumber: string;
  company: string;
  message: string;
  phoneNumber?: string | undefined;
  jobTitle: string;
}

const validationSchema: Yup.Schema<FormValuesT> = Yup.object().shape({
  name: Yup.string()
    .required('Fill in your name')
    .max(150, "Aren't you a bit long for a name?"),
  email: Yup.string()
    .email()
    .required('Fill in your work email address')
    .max(150, "Aren't you a bit long for an email address?"),
  company: Yup.string()
    .required('Fill in your company name')
    .max(150, "Aren't you a bit long for a company name?"),
  jobTitle: Yup.string()
    .required('Fill in your job title')
    .max(100, "Aren't you a bit long for a job title?"),
  licensesNumber: Yup.string().required('Select the number of users'),
  phoneNumber: Yup.string(),
  message: Yup.string().required('Fill in your message'),
});

const createMessage = ({
  name,
  email,
  company,
  jobTitle,
  licensesNumber,
  phoneNumber,
  message,
}: FormValuesT) => `
Name: ${name}
Email: ${email}
Job title: ${jobTitle}
Company name: ${company}
Phone number: ${phoneNumber}

Number of users to include in the subscription: ${licensesNumber}

Reference: IAEF2022

${message}`;

export const ContactSalesModal = ({
  trackingContext,
  showSalesModal,
  setShowSalesModal,
  subHeading,
}: PropsT) => {
  const toast = useToast();
  const { user } = useUser();
  const [buttonState, setButtonState] = useButtonState();
  const [message, setMessage] = useState('');

  const handleSuccess = () => {
    setButtonState('success');
    toast.displayToast({
      description: 'Your message has been sent to the sales team!',
      autoDismiss: true,
    });
    trackUser({
      event: 'Submitted contact sales form in-app',
      properties: { context: trackingContext },
    });
    setShowSalesModal(false);
  };

  const contactSales = async (formValues: FormValuesT) => {
    if (user?.email) {
      try {
        setButtonState('loading');
        const {
          data: { conversation_id: conversationId },
        } = await tracking().createConversation({
          email: user?.email,
          subject: 'Enquiry for Mentimeter Enterprise',
          message: createMessage(formValues),
        });
        await tracking().assignConversation({ conversationId });
        handleSuccess();
      } catch (e) {
        const description =
          e instanceof Error
            ? e.message
            : 'Failed to send message! Please try again later.';
        toast.displayToast({
          description,
          autoDismiss: false,
        });
      } finally {
        setButtonState(undefined);
      }
    }
  };

  return (
    <ModalRoot open={showSalesModal} onOpenChange={setShowSalesModal}>
      <ModalContainer>
        <ModalHeader>Contact our sales team</ModalHeader>
        <ModalBody>
          {subHeading && <Text mb="space6">{subHeading}</Text>}
          <Form
            width="100%"
            initialValues={
              {
                name: user?.name,
                email: user?.email,
                licensesNumber: '',
                company: '',
                message: '',
              } as FormValuesT
            }
            validationSchema={validationSchema}
            onSubmit={contactSales}
          >
            <TextInput
              width="100%"
              name="name"
              aria-label="enter name"
              label="Name"
            />
            <TextInput
              width="100%"
              name="jobTitle"
              aria-label="enter job title"
              label="Job title"
            />
            <TextInput
              width="100%"
              name="company"
              aria-label="enter company name"
              label="Company name"
            />
            <Select
              width="100%"
              name="licensesNumber"
              aria-label="enter number of users to include in the subscription"
              label="Number of users to include in the subscription"
              options={[
                SELECT_DEFAULT_VALUE,
                ...['1-9', '10-20', '21-50', '51-99', '100+'].map((v) => ({
                  key: v,
                  value: v,
                  label: v,
                })),
              ]}
            />
            <TextInput
              width="100%"
              name="email"
              aria-label="enter email"
              label="Email"
            />
            <TextInput
              width="100%"
              name="phoneNumber"
              aria-label="enter phone number"
              hintText="Please include country/region code"
              label="Phone number (optional)"
            />

            <CharacterCountWrap width="100%" multiline mt={3}>
              <Textarea
                name="message"
                aria-describedby="message-character-counter"
                aria-label="enter message"
                label="Message"
                rows={7}
                onChange={(e) => setMessage(e.target.value)}
                inputSize="compact"
              />
              <CharacterCount
                id="message-character-counter"
                maxLength={500}
                value={message}
                multiline
              />
            </CharacterCountWrap>
            <Box
              flexDirection="row"
              mt={3}
              justifyContent="flex-end"
              width="100%"
            >
              <Button
                onClick={() => setShowSalesModal(false)}
                variant="secondary"
                aria-label="cancel"
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                ml={2}
                aria-label="send"
                state={buttonState}
                type="submit"
              >
                Send
              </Button>
            </Box>
          </Form>
        </ModalBody>
      </ModalContainer>
    </ModalRoot>
  );
};

export default ContactSalesModal;
