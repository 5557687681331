/* eslint-disable menti-react/filename-convention--jsx */
import type {
  ListChildComponentProps,
  ListOnItemsRenderedProps,
} from 'react-window';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import React, { type JSX } from 'react';

export const VirtualizedList = ({
  itemsRenderer,
  ...rest
}: {
  itemCount: number;
  itemSize: number;
  itemsRenderer: (props: ListChildComponentProps<any>) => JSX.Element | null;
  onItemsRendered: ((props: ListOnItemsRenderedProps) => any) | undefined;
  overscanCount?: number;
}) => {
  return (
    <AutoSizer>
      {/*The AutoSizer uses the height and width of the parent component to calculate the table size.
      If there is no height and width on the parent, the virtualized content will not be rendered.*/}
      {({ height, width }: { height: number; width: number }) => {
        // eslint-disable-next-line react/jsx-no-useless-fragment
        if (height === undefined || width === undefined) return <></>;
        return (
          <FixedSizeList height={height} width={width} {...rest}>
            {itemsRenderer}
          </FixedSizeList>
        );
      }}
    </AutoSizer>
  );
};
