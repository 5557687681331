import useSWR from 'swr';
import type {
  AxiosErrorToJSON,
  BillingHistoryResponseT,
} from '@mentimeter/http-clients';
import { core, isAxiosError } from '@mentimeter/http-clients';

export const useBillingHistory = (skip = false) => {
  const {
    data: receipts,
    error,
    isLoading,
    isValidating,
  } = useSWR(!skip ? '/payment-services/billing-history' : null, async () => {
    try {
      const response = await core().get<BillingHistoryResponseT>(
        '/payment-services/billing-history',
      );
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        error.message =
          'Could not get your receipts at this time, please try again later or contact support if the issue persists.';
        throw error.toJSON();
      }
      throw error;
    }
  });

  const hasUnpaidInvoice =
    (receipts && receipts?.length > 0 && receipts[0]?.status === 'open') ??
    false;

  return {
    isLoading,
    isValidating,
    receipts,
    hasUnpaidInvoice,
    error: error as AxiosErrorToJSON,
  };
};
