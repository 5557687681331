import { useMatch } from '@mentimeter/ragnar-device';

export const usePlansPageMode = () => {
  const isCompactMode = useMatch({ lessThan: 3 });

  return {
    isCompactMode,
    isFullMode: !isCompactMode,
  };
};
