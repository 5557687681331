import { useDevelopmentFlag } from '@mentimeter/development-tools/hooks';
import type { DevelopmentFlagValue } from '@mentimeter/development-flags';
import { MentiError } from '@mentimeter/errors/sentry';
import { userCache, useUser } from '@mentimeter/user';
import { useEntitlement } from '@mentimeter/entitlements';
import useSWR from 'swr';
import {
  getEngagedParticipantsRequest,
  getEngagedParticipantsResponse,
} from '@core-api/engagement-limits/engagement-limits/engaged-participants';

export const ENGAGED_PARTICIPANTS_UNLIMITED = -1;

export const EngagementStatusNames = [
  'Unengaged',
  'ActivatedLimit',
  'NearingLimit',
  'ReachedLimit',
] as const;
export type EngagementStatusName = (typeof EngagementStatusNames)[number];

export interface EngagementLimitsData {
  progress: number;
  limit: number;
  resetsAt: Date;
  engagementStatus: EngagementStatus;
  gracePeriod?: {
    presentationId: string;
    presentableUntil: Date;
  };
}

export enum EngagementType {
  EngagedParticipants,
}

export enum EngagementStatus {
  Unengaged,
  ActivatedLimit,
  NearingLimit,
  ReachedLimit,
}

const OVERRIDE_LIMIT = 50;

const OVERRIDE_LIMIT_PROGRESS: Record<
  DevelopmentFlagValue<'engagement-limit-progress'>,
  number
> = {
  unengaged: 0,
  'activated-limit': 3,
  'nearing-limit': 30,
  'reached-limit': 95,
};

const OVERRIDE_STATUS: Record<
  DevelopmentFlagValue<'engagement-limit-progress'>,
  EngagementStatus
> = {
  unengaged: EngagementStatus.Unengaged,
  'activated-limit': EngagementStatus.ActivatedLimit,
  'nearing-limit': EngagementStatus.NearingLimit,
  'reached-limit': EngagementStatus.ReachedLimit,
};

const ENGAGEMENT_STATUS_FROM_NAME: Record<
  EngagementStatusName,
  EngagementStatus
> = {
  Unengaged: EngagementStatus.Unengaged,
  ActivatedLimit: EngagementStatus.ActivatedLimit,
  NearingLimit: EngagementStatus.NearingLimit,
  ReachedLimit: EngagementStatus.ReachedLimit,
};

export const ENGAGEMENT_TYPE_NAME = 'EngagedParticipants';

export type EngagementLimits = NonNullable<
  ReturnType<typeof useEngagementLimits>
>;

export const useEngagementLimits = () => {
  const { user } = useUser();
  const engagedParticipantsLimitEntitlement = useEntitlement(
    'engagedParticipantsLimit',
  );

  const shouldFetch =
    user &&
    engagedParticipantsLimitEntitlement &&
    engagedParticipantsLimitEntitlement.value !==
      ENGAGED_PARTICIPANTS_UNLIMITED;

  const engagementLimitsData = useEngagementLimitsData({
    skip: !shouldFetch,
  });

  if (!engagementLimitsData) {
    return null;
  }

  const { progress, resetsAt, limit, gracePeriod, engagementStatus } =
    engagementLimitsData;

  return {
    engagementStatus,
    engagementData: {
      progress,
      limit,
      resetsAt,
      gracePeriod,
    },
  };
};

const ENGAGEMENT_LIMITS_ENDPOINT_KEY = 'engagement-limits/engaged-participants';
const useEngagementLimitsData = ({
  skip = false,
}: {
  skip?: boolean;
}): EngagementLimitsData | null => {
  const { data, error } = useSWR(
    skip ? null : ENGAGEMENT_LIMITS_ENDPOINT_KEY,
    getEngagedParticipantsData,
    {
      revalidateOnFocus: true,
    },
  );

  const overrideStatus = useDevelopmentFlag('engagement-limit-progress');

  if (overrideStatus) {
    const today = new Date();
    const fifteenDaysFromNow = new Date(today.setDate(today.getDate() + 15));

    const status = OVERRIDE_STATUS[overrideStatus];
    const overrideProgress = OVERRIDE_LIMIT_PROGRESS[overrideStatus];

    return {
      progress: overrideProgress,
      limit: OVERRIDE_LIMIT,
      resetsAt: fifteenDaysFromNow,
      engagementStatus: status,
    };
  }

  if (!data || error) return null;

  return data;
};

export function isPresentationLockedByEngagementLimits(
  seriesId: string,
  engagementLimits: EngagementLimits | null,
) {
  if (!engagementLimits) return false;

  const { engagementData, engagementStatus } = engagementLimits;
  const presentationInGracePeriod =
    engagementData.gracePeriod?.presentationId === seriesId;
  const hasReachedEngagementLimit =
    engagementStatus === EngagementStatus.ReachedLimit;

  return hasReachedEngagementLimit && !presentationInGracePeriod;
}

export function getEngagementLimitProgress(progress: number, limit: number) {
  const uncappedProgress = progress * (100 / limit);
  return Math.min(100, uncappedProgress);
}

async function getEngagedParticipantsData(): Promise<EngagementLimitsData> {
  const region = userCache.region;
  const sessionToken = userCache.getToken();

  const response = await fetch(
    getEngagedParticipantsRequest({ region, userAuth: sessionToken }),
  );

  if (!response.ok) {
    throw new MentiError('Failed fetching engagement limits data', {
      feature: 'engagement-limits',
    });
  }

  const data = await getEngagedParticipantsResponse(response);

  const {
    resetsAt,
    engagedParticipants,
    engagedParticipantsLimit,
    engagementStatus: rawEngagementStatus,
    gracePeriod,
  } = data;

  const engagementStatus = ENGAGEMENT_STATUS_FROM_NAME[rawEngagementStatus];

  return {
    resetsAt: new Date(resetsAt),
    progress: engagedParticipants,
    limit: engagedParticipantsLimit,
    engagementStatus,
    ...(gracePeriod && {
      gracePeriod: {
        presentationId: gracePeriod.presentationId,
        presentableUntil: new Date(gracePeriod.presentableUntil),
      },
    }),
  };
}

export const ENGAGEMENT_COPY = {
  engagement_type: 'Participants',
  banner: {
    heading: {
      [EngagementStatus.Unengaged]: () =>
        `You are using the Free version of Mentimeter.`,
      [EngagementStatus.ActivatedLimit]: (limit: number, progress: number) =>
        `You've reached ${progress} out of ${limit} participants this month.`,
      [EngagementStatus.NearingLimit]: (limit: number, progress: number) =>
        `You've reached ${progress} out of ${limit} participants this month.`,
      [EngagementStatus.ReachedLimit]: (limit: number, progress: number) =>
        `You've reached your ${limit} participant limit for this month.`,
    },
    prompt: {
      [EngagementStatus.Unengaged]: () =>
        `Unlock unlimited participants, slide imports, teams, and more.`,
      [EngagementStatus.ActivatedLimit]: (limit: number, progress: number) =>
        `Unlock unlimited participants, slide imports, teams, and more.`,
      [EngagementStatus.NearingLimit]: (limit: number, progress: number) =>
        `Unlock unlimited participants, slide imports, teams, and more.`,
      [EngagementStatus.ReachedLimit]: (limit: number, progress: number) =>
        `Upgrade to keep presenting.`,
    },
  },
  popover_grace_period: {
    limit_reached: 'Participant limit reached',
  },
  plans_modal: {
    limit_reached: "Looks like you're out of participants this month",
  },
  progress_bar: {
    tooltip:
      'The people who vote on your Menti are called participants. You may go over the monthly participants limit during a presentation. After that, future presentations are blocked.',
  },
  presentation_locked: {
    limit_reached: 'Upgrade to engage more participants',
    limit_descriptor: (renderResetsAt: React.ReactNode) => (
      <>
        Your participant limit resets on {renderResetsAt}. Upgrade to keep
        presenting now.
      </>
    ),
  },
};
