export const DiscoverIcon = () => (
  <g fill="white" fillRule="evenodd">
    <rect height="59" rx="4" stroke="#e6772f" width="99" x=".5" y=".5" />
    <path
      d="m24.1154168 60h71.884948c2.2089375 0 3.9996352-1.7871603 3.9996352-3.9946973v-22.3072341s-26.8004659 18.1699282-75.8845832 26.3019314z"
      fill="#e6772f"
    />
    <g fill="#1a1919">
      <path d="m18.2885834 29.8220151c-.7507135.6418944-1.7063807.9225827-3.2346021.9225827h-.6341343v-7.6600008h.6341343c1.5282214 0 2.4472226.2617958 3.2346021.9356276.8127637.6963228 1.301174 1.7704952 1.301174 2.8815529 0 1.1164556-.4884103 2.2288628-1.301174 2.9202376zm-2.7612342-8.699537h-3.4696408v11.5851383h3.4484873c1.8351819 0 3.1598598-.4178837 4.3209508-1.3400166 1.3792069-1.0921652 2.1976115-2.7412088 2.1976115-4.4496287 0-3.4199886-2.6700392-5.795493-6.4974088-5.795493z" />
      <path d="m23.1139272 21.1224781h2.352737v11.5851383h-2.352737z" />
      <path d="m31.2443847 25.5626606c-1.4201037-.4984016-1.8351819-.8303695-1.8351819-1.4565202 0-.7278103.741782-1.2797406 1.7618498-1.2797406.7069963 0 1.2894221.2707922 1.9099242.9324788l1.228312-1.5419861c-1.0148969-.8546598-2.2295767-1.2873876-3.5537846-1.2873876-2.1426124 0-3.7756611 1.4263822-3.7756611 3.3124814 0 1.6013626.7591749 2.4137392 2.9746494 3.1851821.9260523.3081273 1.3965997.5186435 1.6349289.6612368.4719577.2919338.7069963.7102672.7069963 1.1938247 0 .9423749-.7803283 1.6350992-1.8351819 1.6350992-1.1230147 0-2.0316742-.5379859-2.5755537-1.5482837l-1.5221103 1.4106384c1.088229 1.5257927 2.3926936 2.2077212 4.1897992 2.2077212 2.4509832 0 4.1780473-1.5703249 4.1780473-3.8068346 0-1.8429164-.8010118-2.6782339-3.4870336-3.6179098" />
      <path d="m35.4732003 26.9179711c0 3.4069438 2.7946097 6.0469429 6.3921115 6.0469429 1.0144269 0 1.88407-.1916238 2.9549061-.6751813v-2.6624901c-.9462656.9099878-1.7768923 1.2689449-2.8514891 1.2689449-2.377181 0-4.060998-1.6458948-4.060998-3.99441 0-2.2189667 1.738346-3.9768669 3.957581-3.9768669 1.1197242 0 1.975265.3796489 2.9549061 1.3031313v-2.6593414c-1.0346402-.5006507-1.8835998-.7080182-2.9064881-.7080182-3.5768184 0-6.4405295 2.6917285-6.4405295 6.0572888" />
      <path d="m63.9486046 28.900332-3.2284911-7.7778539h-2.5741435l5.1323043 11.8775219h1.2659183l5.2319606-11.8775219h-2.5539301z" />
      <path d="m70.8474595 32.7076164h6.6953114v-1.9630186h-4.3331729v-3.1271552h4.1686458v-1.9625687h-4.1686458v-2.5702769h4.3331729v-1.9621189h-6.6953114z" />
      <path d="m82.1542294 26.4497075h-.6853727v-3.5041051h.7239191c1.4751026 0 2.2653026.5924142 2.2653026 1.7210149 0 1.1596384-.7902 1.7830902-2.303849 1.7830902zm4.735089-1.9090401c0-2.1694864-1.5550158-3.4181893-4.2809943-3.4181893h-3.5067768v11.5851383h2.3673094v-4.6578958h.3069605l3.2618666 4.6578958h2.9046078l-3.8090366-4.8828063c1.7801828-.3481614 2.7560634-1.5113984 2.7560634-3.2841427z" />
    </g>
    <path
      d="m58.4787848 26.9449603c0 3.3354223-2.8242246 6.0388461-6.3112582 6.0388461-3.4860935 0-6.3107882-2.7034238-6.3107882-6.0388461 0-3.335872 2.8246947-6.0397457 6.3107882-6.0397457 3.4870336 0 6.3112582 2.7038737 6.3112582 6.0397457"
      fill="#e6772f"
    />
  </g>
);
