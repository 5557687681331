import type { WorkspaceMembersResponseT } from '@mentimeter/http-clients';
import { core } from '@mentimeter/http-clients';
import useSWR from 'swr';

export const useOrganizationMembersUnpaginated = ({
  queryString = '',
  skip = false,
  revalidateOnFocus = true,
} = {}) => {
  const getKey = () => {
    if (skip) return undefined;

    return `/workspaces/members/unpaged${queryString}`;
  };

  const { data, error, mutate } = useSWR<WorkspaceMembersResponseT>(
    getKey,
    async (url) => {
      const { data } = await core().get<WorkspaceMembersResponseT>(url);
      return data;
    },
    { shouldRetryOnError: false, revalidateOnFocus },
  );

  return {
    members: data?.data || [],
    error,
    mutate,
  };
};
