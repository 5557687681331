import useSWR from 'swr';
import type { AxiosErrorToJSON, PlanCategory } from '@mentimeter/http-clients';
import { core } from '@mentimeter/http-clients';

interface UpgradeOptionsT {
  plan_category: PlanCategory;
  licenses: { from: number; up_to: number };
}

export interface UpgradeOptionsResponseT {
  current_plan: {
    plan_category: PlanCategory;
    licenses: number;
  };
  upgrade_options: UpgradeOptionsT[];
}

export const useUpgradeOptions = (skip = false) => {
  const { data: possibleUpgrades, error } = useSWR(
    !skip ? 'payment-services/subscriptions/upgrade-options' : null,
    async () => {
      const { data } = await core().get<UpgradeOptionsResponseT>(
        '/payment-services/subscriptions/upgrade-options',
      );

      return data;
    },
  );

  return {
    isLoading: !possibleUpgrades && !error,
    possibleUpgrades,
    error: error as AxiosErrorToJSON,
  };
};

export const getValidPlan = (
  toPlan: string,
  possibleUpgrades: UpgradeOptionsResponseT,
): UpgradeOptionsT | undefined => {
  return possibleUpgrades?.upgrade_options.find(
    (plan) => plan.plan_category === toPlan,
  );
};
