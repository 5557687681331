import { trackUser } from '@api/tracking/client';
import type { TrackCheckoutEventParamsT } from '@mentimeter/billing-address';

export function compact<T extends object>(obj: T): T {
  return Object.entries(obj).reduce((acc, [k, v]) => {
    if (v && typeof v === 'object') {
      // If the value is an object, recursively call compact on it
      const compacted = compact(v);
      return Object.keys(compacted).length > 0
        ? { ...acc, [k]: compacted }
        : acc;
    } else if (v !== null && v !== undefined && v !== '' && !Number.isNaN(v)) {
      // If the value is not an object and not null, undefined, '', or NaN, keep it
      return { ...acc, [k]: v };
    } else {
      // If the value is null, undefined, '', or NaN, remove it
      return acc;
    }
  }, {}) as T;
}

export function trackCheckoutEvent({
  event,
  additionalProperties,
}: TrackCheckoutEventParamsT) {
  trackUser({
    event,
    properties: {
      context: 'Checkout',
      ...additionalProperties,
    },
  });
}
