import { useCallback } from 'react';
import { useUser } from '@mentimeter/user';
import { useSubscriptions, billingRules } from '@mentimeter/billing';
import { useWorkspace } from '@mentimeter/workspace-data-hooks';
import { useEngagementLimits } from '@mentimeter/paywalls-data-hooks';
import { trackUser } from '@api/tracking/client';
import { trackVisitor } from 'src/trackVisitor';
import { gtmTrack } from '@mentimeter/google-tracking';

export const useTrackPlan = (isBasicMonthlyUser?: boolean) => {
  const { user } = useUser();
  const { subscriptions } = useSubscriptions(!user);
  const { planName } = billingRules(subscriptions);
  const { data: workspace } = useWorkspace();
  const engagementLimits = useEngagementLimits();

  const trackPlan = useCallback(
    (plan: string) => {
      const current = planName || 'registered';

      const switchToYearlyPayload = {
        event: 'Clicked switch to yearly',
        properties: {
          context: 'Plans page',
        },
      };

      const buyPlanPayload = {
        event: 'Clicked buy plan',
        properties: {
          plan,
          context: 'Plans page',
          'current plan': current.toLowerCase(),
          'active team members': workspace?.memberStats?.active || 0,
          'engaged participants': engagementLimits?.engagementData.progress,
        },
      };

      const payload = isBasicMonthlyUser
        ? switchToYearlyPayload
        : buyPlanPayload;

      gtmTrack({
        event: 'selectProduct',
        type: plan,
        currentMembership: planName,
      });
      if (user) {
        trackUser(payload);
      } else {
        trackVisitor(payload);
      }

      if (plan === 'enterprise' || plan === 'educational-enterprise') {
        trackVisitor({ event: 'Clicked new enterprise plans copy' });
      }
    },
    [
      planName,
      workspace?.memberStats?.active,
      engagementLimits?.engagementData.progress,
      isBasicMonthlyUser,
      user,
    ],
  );

  return { trackPlan };
};
